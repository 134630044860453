// Customizable Area Start
//@ts-nocheck
import React from "react";
import MyNewSubscriptionsController, {
  Props,
} from "./MyNewSubscriptionsController.web";
import {
  Box,
  Typography,
  Button,
  Grid,
  withStyles,
  createStyles,
  AppBar,
  Toolbar,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { withRouter } from "react-router";
import Alert from "@material-ui/lab/Alert";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import withConfirmBox from "../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { Formik, Form } from "formik";
import moment from "moment";
import { crossIcon, greenTick } from "./assets";
import PlusMinusButton from "../../../components/src/PlusMinusButton.web";
import CustomGrid from "../../../components/src/CustomGrid.web";
import CustomTypography from "../../../components/src/CustomTypography.web";
import CancelSubscriptionDialog from "./CancelSubscriptionDialog.web";
import withPermissions from "../../../components/src/withPermissions.web";
import AddSignatureDialog from "./AddSignatureDialog.web";
export class MyNewSubscriptions extends MyNewSubscriptionsController {
  constructor(props: Props) {
    super(props);
    this.updateChecksToExistingSubscription.bind(this);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");
    this.setState(
      {
        token: token,
        accountId: accountId,
      },
      () => {
        this.getProfile();
      }
    );
  }

  openCancelSubscriptionDialog = (data) => {
    this.props
      .openDialogBox({
        dataToPass: {
          package: data,
        },
        width: "60%",
        renderedComponent: CancelSubscriptionDialog,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data) {
          this.cancelMyCurrentSubscription();
        }
      });
  };
  getOneTimeFessForToBeUpdatedSubuscription = (totalOneTimeFess) => {
    let oneTimefees = totalOneTimeFess;
    if (this.state.managingSubscription) {
      const blueprintfees = this.state.subscriptionInitialValues
        ?.isSmartBlurPrint
        ? 495
        : 0;
      const smart_implementation_fees = this.state.subscriptionInitialValues
        ?.isSmartImplimentation
        ? 2995
        : 0;
      oneTimefees =
        totalOneTimeFess - blueprintfees - smart_implementation_fees;
    }
    return oneTimefees;
  };
  getAddonBoxes = (values, setFieldValue) => {
    return [
      {
        label: (
          <Typography
            display="inline"
            style={{
              fontSize: "1.2rem",
            }}
          >
            <span  data-test-id="additional-users-text"style={{color:'#ED1867',fontSize:'14px',fontWeight:800}}>Additional Users:</span> <span data-test-id="subscription-info-text">   1 User is included with the subscription. I want{" "}</span>
            <PlusMinusButton
              initialValue={values.additionalUsersCount}
              disableValue={1}
              onDecrement={(event) => {
                event.stopPropagation()
                setFieldValue(
                  "additionalUsersCount",
                  +values.additionalUsersCount - 1
                );
              }}
              onIncrement={(event) => {
                event.stopPropagation()
                setFieldValue(
                  "additionalUsersCount",
                  +values.additionalUsersCount + 1
                );
              }}
              data-test-id={"increment"}
            />{" "}
            additional user(s) for $40/month each
          </Typography>
        ),
        isDisable:
          !values.planRadioButton ||
          values.planRadioButton ==
          this.state.subscriptionInitialValues.planRadioButton,
        name: "isAdditionaUser",
        id: 3333,
      },
      {
        label: (
          <Typography
            display="inline"
            style={{
              fontSize: "1.2rem",
            }}
          >
           <span style={{color:'#ED1867',fontSize:'14px',fontWeight:800}}>Digital Marketing Makeover:</span> Custom Website, Custom Client Guide, & Custom Package Flyer for $2495
        </Typography>
        )
        // id: 4444,
        // name: "isSmartBlurPrint",
        // isDisable: !values.planRadioButton
        //   ? true
        //   : this.state.subscriptionInitialValues.isSmartBlurPrint ||
        //   values.planRadioButton ==
        //   this.state.subscriptionInitialValues.planRadioButton,
      },
    ];
  };
  getSubuscriptionFeesPlan = (values) => {
    let subuscriptionFees = "";
    let subuscriptionFeesPlan = "";
    if (values?.selectedPackage?.name === "Engage") {
      if (values.planRadioButton === "engageMonthly") {
        subuscriptionFees = 95 + "/month";
        subuscriptionFeesPlan = "/month";
      } else if (values.planRadioButton === "engageAnnual") {
        subuscriptionFees = 95 * 12 - 95 * 12 * 0.2 + "/year";
        subuscriptionFeesPlan = "/year";
      }
    } else if (values?.selectedPackage?.name === "Advise") {
      if (values.planRadioButton === "adviceMonthly") {
        subuscriptionFees = 225 + "/month";
        subuscriptionFeesPlan = "/month";
      } else if (values.planRadioButton === "adviceAnnual") {
        subuscriptionFees = 225 * 12 - 225 * 12 * 0.2 + "/year";
        subuscriptionFeesPlan = "/year";
      }
    }
    else if (values?.selectedPackage?.name === "Accelerate") {
      if (values.planRadioButton === "accelerateMonthly") {
        subuscriptionFees = 625 + "/month";
        subuscriptionFeesPlan = "/month";
      } else if (values.planRadioButton === "accelerateAnnual") {
        subuscriptionFees = 625 * 12 - 625 * 12 * 0.2 + "/year";
        subuscriptionFeesPlan = "/year";
      }
    }
    return { subuscriptionFees, subuscriptionFeesPlan };
  };
  getTotalRecurringFeesBySelectedPackage = (values) => {
    let totalRecurringFees = 0;
    if (values?.selectedPackage?.name === "Engage") {
      if (values.planRadioButton === "engageMonthly") {
        console.log("Plan is engageMonthly");
        totalRecurringFees += 95;
      } else if (values.planRadioButton === "engageAnnual") {
        totalRecurringFees += 95 * 12 - 95 * 12 * 0.2;
      }
    } else if (values?.selectedPackage?.name === "Advise") {
      if (values.planRadioButton === "adviceMonthly") {
        totalRecurringFees += 225;
      } else if (values.planRadioButton === "adviceAnnual") {
        totalRecurringFees += 225 * 12 - 225 * 12 * 0.2;
      }
    }
    else if (values?.selectedPackage?.name === "Accelerate") {
      if (values.planRadioButton === "accelerateMonthly") {
        totalRecurringFees += 625;
      } else if (values.planRadioButton === "accelerateAnnual") {
        totalRecurringFees += 625 * 12 - 625 * 12 * 0.2;
      }
    }
    return totalRecurringFees;
  };
  getTotalRecurringFeesBySelectedAddons = (values, totalRecurringFee) => {
    let totalRecurringFees = totalRecurringFee;
    const isAnnual = values?.planRadioButton?.indexOf("Annual") > -1;

    if (values?.isAdditionaUser) {
      const additionalUserFee = 40 * +(values.additionalUsersCount || 0);
      totalRecurringFees += isAnnual ? additionalUserFee * 12 : additionalUserFee;
    }

    if (values?.isSmartSupport) {
      const smartSupportFee = 625;
      totalRecurringFees += isAnnual ? smartSupportFee * 12 : smartSupportFee;
    }

    if (values?.smart_lead) {
      const smartLeadFee = 925;
      totalRecurringFees += isAnnual ? smartLeadFee * 12 : smartLeadFee;
    }

    return totalRecurringFees;
  };

  getTotalRecurringFeeAndtotalOneTimeFees = (values) => {
    let totalRecurringFees = this.getTotalRecurringFeesBySelectedPackage(
      values
    );
    totalRecurringFees = this.getTotalRecurringFeesBySelectedAddons(
      values,
      totalRecurringFees
    );
    let totalOneTimeFess = 0;
    if (values?.isSmartImplimentation) {
      totalOneTimeFess += 2995;
    }
    if (values?.isSmartBlurPrint) {
      totalOneTimeFess += 495;
    }
    if (values?.issignatureBundle) {
      totalOneTimeFess += 22 *  (values.signatureBundleCount || 0);
    }
    return { totalRecurringFees, totalOneTimeFess };
  };

  toggleFilter = (value) => {
    this.setState({ filterValue: value });
  };
  getSelectProductClassName = (product, values, classes) => {
    let classNames = null;
    if (product?.attributes?.name === "Engage") {
      if (values.selectedPackage?.name === product.attributes.name) {
        classNames = classes.engagePackageSelected;
      } else {
        classNames = classes.engagePackage;
      }
    } else if (product?.attributes?.name === "Advise") {
      if (values.selectedPackage?.name === product.attributes.name) {
        classNames = classes.advicePackageSelected;
      } else {
        classNames = classes.advicePackage;
      }
    }
    return classNames;
  };
  handleMouseEnter = (index) => {
    this.setState({ hoveredColumn: index });
  };

  handleMouseLeave = () => {
    this.setState({ hoveredColumn: null });
  };
  normalizeProducts(products) {
    if (Array.isArray(products)) {
      return products;
    }
    if (products && typeof products === "object") {
      return [products];
    }
    return [];
  }
 
  renderRadioBoxGroup = (values, classes, setFieldValue, handleChange) => {
    const planTypes = ["Advise", "Engage", "Accelerate"];
    const isMonthly = this.state.filterValue === "Monthly";
    
    const planKeyMap = {
      Advise: isMonthly ? "adviceMonthly" : "adviceAnnual",
      Engage: isMonthly ? "engageMonthly" : "engageAnnual",
      Accelerate: isMonthly ? "accelerateMonthly" : "accelerateAnnual",
    };
    const getPlanKey = (planName) => planKeyMap[planName];
  
    const handlePlanSelection = (planName) => {
      const selectedPlan = this.state.productList.find((pdt) => pdt.attributes.name === planName);
      setFieldValue("planRadioButton", getPlanKey(planName));
      setFieldValue("selectedPackage", {
        name: planName,
        id: selectedPlan?.id,
      });
      handleChange({ target: { value: getPlanKey(planName) } });
    };
  
    return (
      <Box className={classes.planSelectionContainer} data-test-id={isMonthly ? "radioMonthly" : "radioAnnual"}>
        {planTypes.map((planName) => {
          const planKey = getPlanKey(planName);
          const isSelected = values?.planRadioButton === planKey;
  
          return (
            <Button
              key={planKey}
              data-test-id={`select${planName}${isMonthly ? "" : "Yearly"}`}
              className={`${classes.plansButtons} ${isSelected ? classes.selectedPlanButton : ""}`}
              onClick={() => handlePlanSelection(planName)}
            >
              Select {planName} Plan<br style={{ marginTop: "5px" }} />
              ({isMonthly ? "Monthly" : "Annual Save 20%"})
            </Button>
          );
        })}
      </Box>
    );
  };
  
  renderProductFeatureList(products, values) {
    const { classes } = this.props;
    products = this.normalizeProducts(products);
     
    if (!products.length) {
      return <p>No products available.</p>;
    }
  
    const features = products[0].attributes?.features?.data || [];
  
   
    const renderProductHeader = (product, index) => {
      const isSelected = values?.selectedPackage?.name === product.attributes?.name;
      const isHovered = this.state.hoveredColumn === index;
  
      return (
        <th
          key={index}
          onMouseEnter={() => this.handleMouseEnter(index)}
          onMouseLeave={this.handleMouseLeave}
          className={`${isSelected ? classes.hoveredColumn : ""} ${classes.productHeader} ${
            isHovered ? classes.hoveredColumn : ""
          }`}
        >
          {isSelected && (
            <div>
              <Typography style={{ color: "#ED1867", fontSize: "12px", fontWeight: 800 }}>
                My Subscriptions
              </Typography>
            </div>
          )}
          <div className={classes.productPrice}>
            <Typography className={classes.productTitle}>{product.attributes?.name}</Typography>
          </div>
          <div className={classes.productPrice}>
            <Typography className={classes.productPriceText}>
              ${product.attributes?.description?.split(" ")[2]?.replace("$", "")}
            </Typography>
          </div>
          <div className={classes.productPrice}>
            <Typography className={classes.productDescriptionText}>
              {product.attributes?.description?.split(" When ")[1]}
            </Typography>
          </div>
        </th>
      );
    };
  
    const renderFeatureCell = (feature, product, colIndex, isLastRow) => {
      const isSelected = values?.selectedPackage?.name === product.attributes?.name;
      const isHovered = this.state.hoveredColumn === colIndex;
      const hasFeature = product.attributes?.features?.data?.some(
        (f) => f.attributes.title === feature.attributes.title
      );
     return (
        <td
          key={colIndex}
          onMouseEnter={() => this.handleMouseEnter(colIndex)}
          onMouseLeave={this.handleMouseLeave}
          className={`${classes.featureCell} ${
            isLastRow ? classes.hoveredFeatureCell1 : ""
          } ${isSelected ? classes.hoveredFeatureCell : ""} ${isHovered ? classes.hoveredFeatureCell : ""}`}
        >
          <img
            src={hasFeature ? greenTick : crossIcon}
            className={classes.featureIcon}
            alt="feature status"
          />
        </td>
      );
    };
  
    return (
      <div className={classes.productFeatureContainer}>
        <table className={classes.productFeatureTable}>
          <thead>
            <tr>
              <th className={classes.emptyHeader}></th>
              {products.map((product, index) => renderProductHeader(product, index))}
            </tr>
          </thead>
          <tbody>
            {features.map((feature, rowIndex) => (
              <tr key={rowIndex}>
                <td className={classes.featureTitleCell}>{feature.attributes.title}</td>
                {products.map((product, colIndex) =>
                  renderFeatureCell(feature, product, colIndex, rowIndex === features.length - 1)
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
  
renderProductList = (classes, values) => {
    const isSummaryView = true;

    return this.state.productList.length > 0 ? (
      <Grid
        container
        spacing={3}
        justifyContent="center"
        className={classes.flexContainer}
      >
        <Grid item xs={12} style={{paddingTop:'0px',paddingBottom:'0px'}}>
          {this.renderProductFeatureList(this.state.productList,values)}
        </Grid>
      </Grid>
    ) : null;
  };
  renderButtonGroup=(classes)=>{
    return(
      <Box style={{ display: 'flex', gap: '8px' }}>
      <Box className={classes.savingPercent}>{"(Save 20%)"}</Box>
      <Box className={classes.buttonGroup}>
        {['Yearly', 'Monthly'].map((filter) => (
          <Button
            key={filter}
            data-test-id={`${filter}button`}
            className={
              this.state.filterValue === filter
                ? classes.selected
                : classes.notSelected
            }
            onClick={
              this.state.filterValue === filter
                ? null // Disable click event for the selected button
                : () => this.toggleFilter(filter)
            }
          >
            {filter}
          </Button>
        ))}
      </Box>
    </Box>
    
    )

  }
 
 
 
 

 
  
  
  
 
 
 

 
  renderAddOnsCard = (addonCheckboxes, values, handleChange, classes) => {
    return addonCheckboxes.map((item) => {
        return (
          <Grid key={item.id} item container alignItems="center" xs={12}>
            <FormControlLabel

              control={
                <Checkbox
                  checked={values[item.name]}
                  onChange={handleChange}
                  name={item.name}
                  disabled={item.isDisable}
                  color="primary"
                />
              }
              label={item.label}

              classes={{
                label: classes.addonCheckboxLabel,
              }}
            />
          </Grid>
        );
      })
     
  };
  renderCustomGridOne = (values) => {
    return (
      <CustomGrid item xs={6} width="30%">
        <CustomTypography
          color="textSecondary"
          fontSize="1.2rem"
          margin="1rem 0px"
        >
          Current Subscription Level
        </CustomTypography>
        {values?.isAdditionaUser && (
          <CustomTypography
            color="textSecondary"
            fontSize="1.2rem"
            margin="1rem 0px"
          >
            No. Users:
          </CustomTypography>
        )}
        <CustomTypography
          color="textSecondary"
          fontSize="1.2rem"
          margin="1rem 0px"
        >
          Signature Count:
        </CustomTypography>
        {values?.isSmartImplimentation && (
          <CustomTypography
            color="textSecondary"
            fontSize="1.2rem"
            margin="1rem 0px"
          >
            Smart Implementation:
          </CustomTypography>
        )}
        {values?.isSmartBlurPrint && (
          <CustomTypography
            color="textSecondary"
            fontSize="1.2rem"
            margin="1rem 0px"
          >
            Smart BluePrint:
          </CustomTypography>
        )}
        {values?.isSmartSupport && (
          <CustomTypography
            color="textSecondary"
            fontSize="1.2rem"
            margin="1rem 0px"
          >
            Smart Support :
          </CustomTypography>
        )}
        {values?.smart_lead && (
          <CustomTypography
            color="textSecondary"
            fontSize="1.2rem"
            margin="1rem 0px"
          >
            Smart Lead:
          </CustomTypography>
        )}
      </CustomGrid>
    );
  };
  renderCustomGridTwo = (values, subuscriptionFees) => {
    return (
      <CustomGrid item xs={2} width="20%">
        <CustomTypography
          color="textSecondary"
          fontSize="1.2rem"
          margin="1rem 0px"
        >
          {values?.selectedPackage?.name}
        </CustomTypography>
        {values?.isAdditionaUser && (
          <CustomTypography
            color="textSecondary"
            fontSize="1.2rem"
            margin="1rem 0px"
          >
            {values.additionalUsersCount || 0}
          </CustomTypography>
        )}
        <CustomTypography
          color="textSecondary"
          fontSize="1.2rem"
          margin="1rem 0px"
        >
          0/{values?.signatureBundleCount * 20}
        </CustomTypography>

        {values?.isSmartImplimentation && (
          <CustomTypography
            color="textSecondary"
            fontSize="1.2rem"
            margin="1rem 0px"
          >
            $ 2,995 (One Time)
          </CustomTypography>
        )}
        {values?.isSmartBlurPrint && (
          <CustomTypography
            color="textSecondary"
            fontSize="1.2rem"
            margin="1rem 0px"
          >
            $ 495 (One Time)
          </CustomTypography>
        )}
        {values?.isSmartSupport && (
          <CustomTypography
            color="textSecondary"
            fontSize="1.2rem"
            margin="1rem 0px"
          >
            {subuscriptionFees.indexOf("year") > -1
              ? `$ ${12 * 625} /year`
              : `$ ${625} /month`}
          </CustomTypography>
        )}
        {values?.smart_lead && (
          <CustomTypography
            color="textSecondary"
            fontSize="1.2rem"
            margin="1rem 0px"
          >
            {subuscriptionFees.indexOf("year") > -1
              ? `$ ${12 * 925} /year`
              : `$ ${925} /month`}
          </CustomTypography>
        )}
      </CustomGrid>
    );
  };
  openAddSignatureDialog = () => {
    this.props
      .openDialogBox({
        dataToPass: {},
        width: "60%",
        renderedComponent: AddSignatureDialog,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data) {
          this.createSingaturePayment(
            data.signatureCount,
            this.state.subscriptionInitialValues?.selectedPackage?.id,
            this.state.subscriptionInitialValues
          );
        }
      });
  };
  renderCurrentSubscription = (
    values,
    subuscriptionFees,
    totalRecurringFees,
    subuscriptionFeesPlan,
    totalOneTimeFess
  ) => {
    const { classes } = this.props;
    return this.state.subscribed ? (
      <Grid container>
        {this.renderCustomGridOne(values)}
        {this.renderCustomGridTwo(values, subuscriptionFees)}
        <CustomGrid width="30%">
          <CustomTypography
            color="textSecondary"
            fontSize="1.2rem"
            margin="1rem 0px"
          >
            $ {totalRecurringFees + subuscriptionFeesPlan} + ${" "}
            {totalOneTimeFess + " One time fees"}
          </CustomTypography>
          {values?.isAdditionaUser && (
            <CustomTypography
              color="textSecondary"
              fontSize="1.2rem"
              margin="1rem 0px"
            >
              ${" "}
              {subuscriptionFees.indexOf("year") > -1
                ? ` ${12 * 40 * values?.additionalUsersCount} /year`
                : ` ${40 * values?.additionalUsersCount} /month`}
            </CustomTypography>
          )}
          <CustomTypography
            style={{ display: "flex", alignItems: "center" }}
            component="div"
            color="textSecondary"
            fontSize="1.2rem"
            margin="1rem 0px"
          >
            <span>$ {22 * +values.signatureBundleCount} One Time</span>
            <Button
              variant="text"
              color="primary"
              onClick={this.openAddSignatureDialog.bind(this)}
              className={classes.addSignatureButton}
              disableRipple
              disableFocusRipple
              disableTouchRipple
            >
              Add Signature
            </Button>
          </CustomTypography>

          {values?.isSmartImplimentation && (
            <CustomTypography
              color="textSecondary"
              fontSize="1.2rem"
              margin="1rem 0px"
            >
              Yes
            </CustomTypography>
          )}
          {values?.isSmartBlurPrint && (
            <CustomTypography
              color="textSecondary"
              fontSize="1.2rem"
              margin="1rem 0px"
            >
              Yes
            </CustomTypography>
          )}
          {values?.isSmartSupport && (
            <CustomTypography
              color="textSecondary"
              fontSize="1.2rem"
              margin="1rem 0px"
            >
              Yes
            </CustomTypography>
          )}
          {values?.smart_lead && (
            <CustomTypography
              color="textSecondary"
              fontSize="1.2rem"
              margin="1rem 0px"
            >
              Yes
            </CustomTypography>
          )}
        </CustomGrid>
      </Grid>
    ) : null;
  };
  renderCancelButton = (classes) => {
    return this.state.subscribed ? (
      <Grid
        item
        xs={3}
        style={{
          flexBasis: "23%",
          maxWidth: "23%",
          marginRight: "2%",
        }}
      >
        <Button
          data-test-id="cancelsub"
          fullWidth
          onClick={() => {
            this.openCancelSubscriptionDialog();
          }}
          className={classes.CancelbuttonClass}
        >
          Cancel My Subscription
        </Button>
      </Grid>
    ) : null;
  };
  renderSubscribeButon = (classes, values) => {
    const { permissions } = this.props;
    let disableSubscribe = true;
    if (
      values?.selectedPackage?.name === "Engage" ||
      values?.selectedPackage?.name === "Advise"
    ) {
      if (values.planRadioButton) {
        disableSubscribe = false;
      }
    }
    return (
      <Grid item xs={3}
        style={{
          flexGrow: '0',
          maxWidth: '100%',
          flexBasis: '100%'
        }}>
        {this.state.subscribed ? (
          <div style={{
            display: 'flex', justifyContent: 'start',
            width: '100%',
            gap: '13px'
          }}>
            <Button
            data-test-id="Reset"
              fullWidth
              style={{
                backgroundColor: "#fc0064",
                color: "#fff",
              }}
              className={classes.buttonClass1}
              onClick={() => {
               this.ResettoMyCurrentSubscription();
              }}
            >
           Reset To My Current <br/>Subscription
         </Button>
            <Button
            data-test-id="Reset"
type="submit"
              fullWidth
             
              style={{
                backgroundColor: "#fc0064",
                color: "#fff",
              }}
              className={classes.buttonClass1}
            > Confirm Changes to  My Subscription

            </Button>
          </div>
        ) : (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            disabled={
              values?.selectedPackage === null
                ? true
                : disableSubscribe ||
                !permissions.includes("Subscribe-Subscriptions")
            }
            className={classes.buttonClass}
          >
            Subscribe
          </Button>
        )}
      </Grid>
    );
  };
  renderSubscribedBottomBar = (
    classes,
    values,
    totalRecurringFees,
    totalOneTimeFess
  ) => {
    return !this.state.managingSubscription ? (
      <AppBar position="fixed" color="primary" className={classes.appBarStyles}>
        <Toolbar>
          <Grid container>
            <Grid
              item
              xs={12}
              container
              alignItems="center"
              style={{
                flexBasis: "77%",
                maxWidth: "77%",
                marginLeft: "18%",
                marginRight: "5%",
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Grid item xs={this.state.subscribed ? 6 : 9} style={{ maxWidth: '38%', alignContent: 'center' }}>
                  <Typography
                    color="primary"
                    align="center"
                    className={classes.priceTextLarge}
                    style={{ width: '100%' }}
                  >
                    $ {totalRecurringFees}
                    <Typography
                      display="inline"
                      className={classes.priceTextSmall}
                    >
                      {values?.planRadioButton?.indexOf("Monthly") > -1
                        ? "Monthly"
                        : "Yearly"}{" "}
                      Recurring +
                    </Typography>{" "}
                    $ {totalOneTimeFess}
                    <Typography
                      display="inline"
                      className={classes.priceTextSmall}
                    >
                      One Time
                    </Typography>
                  </Typography>
                </Grid>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  {this.renderCancelButton(classes)}
                  {this.renderSubscribeButon(classes, values)}
                </div>
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    ) : null;
  };
  render() {
    const { classes } = this.props;
    return (
      <Formik
        initialValues={this.state.subscriptionInitialValues}
        enableReinitialize
        data-testid="formik_container"
        onSubmit={(values) => {
          if (this.state.subscribedData?.attributes?.id) {
            this.updateChecksToExistingSubscription(values);
          } else {
            this.createSubscription(values);
          }
        }}
      >
        {(formikProps) => {
          const { values, handleChange, setFieldValue } = formikProps;
          const fees = this.getTotalRecurringFeeAndtotalOneTimeFees(values);
          const totalRecurringFees = fees?.totalRecurringFees;
          const totalOneTimeFess = fees?.totalOneTimeFess;
          const tempSubuscription = this.getSubuscriptionFeesPlan(values);
          let subuscriptionFees = tempSubuscription?.subuscriptionFees;
          let subuscriptionFeesPlan = tempSubuscription?.subuscriptionFeesPlan;
          const addonCheckboxes = this.getAddonBoxes(values, setFieldValue);
          return (
            <Form autoComplete="off" noValidate>
              <Grid container justifyContent="space-between">
                <Grid item md={12}>
                  <Typography className={classes.title}>
                    My Subscriptions
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={[classes.whiteCard, classes.gridSpacing]}
                >
                   <Grid container justifyContent="center">
                  <Box style={{ marginBottom:'40px',marginTop:'20px' }}>
                  {this.renderButtonGroup(classes)}
                  </Box>
                  </Grid>
                  <Grid container justifyContent="center">
                    <Grid xs={10} item>
                      {this.state.isPlanChangedForNextCycle && (
                        <Alert severity="success">
                          {" "}
                          You have updated your subscription level and your new
                          plan will be activated after current subscription ends on{" "}
                          {moment(
                            this.state?.currentSubscription
                              ?.scheduled_subscription_date
                          ).format("DD/MM/YYYY")}.
                        </Alert>
                      )}
                      {this.state.isSubscriptionAlreadyCanceled && (
                        <Alert severity="error">
                          You have cancelled your current subscription on{" "}
                          {moment(
                            this.state?.currentSubscription?.sub_canceled_at
                          ).format("DD/MM/YYYY")}{" "}
                          and all our current services will end on &nbsp;
                          {moment(
                            this.state?.currentSubscription?.sub_cancel_at
                          ).format("DD/MM/YYYY")}.
                        </Alert>
                      )}
                    </Grid>
                    <Grid item xs={10}>
                      <Grid container justifyContent="space-between">
                        {this.renderProductList(classes, values)}
                        </Grid>
                        </Grid>
                        <Box style={{ marginTop:'40px',marginBottom:'25px',display:'flex',justifyContent:'center',width:'100%' }}>
                        {this.renderButtonGroup(classes)}
                       </Box>
                        {
                          this.renderRadioBoxGroup(
                            values,
                            classes,
                            setFieldValue,
                            handleChange
                          )}
                   

                    <Grid
                      data-test-id="grid-component"
                      item
                      xs={11}
                      container
                      className={[classes.gridSpacing, classes.darkGreyCard]}
                      style={{
                        marginBottom:"2.5rem"}}
                    >
                      {this.renderAddOnsCard(
                        addonCheckboxes,
                        values,
                        handleChange,
                        classes
                      )}
                      {this.renderCurrentSubscription(
                        values,
                        subuscriptionFees,
                        totalRecurringFees,
                        subuscriptionFeesPlan,
                        totalOneTimeFess
                      )}
                    </Grid>

                    {this.renderSubscribedBottomBar(
                      classes,
                      values,
                      totalRecurringFees,
                      totalOneTimeFess
                    )}

                   
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const MySubscriptionsWithRouter = withRouter(MyNewSubscriptions);
const MySubscriptionsAlertBox = withAlertBox(MySubscriptionsWithRouter);
const MySubscriptionsLoader = withLoader(MySubscriptionsAlertBox);
const MySubscriptionsToast = withToast(MySubscriptionsLoader);
const MySubscriptionsWithDialog = withDialog(MySubscriptionsToast);
const MySubscriptionsWithConfirmBox = withConfirmBox(MySubscriptionsWithDialog);
const MySubscriptionsWithPermissions = withPermissions(
  MySubscriptionsWithConfirmBox
);

export default withStyles(
  (theme) =>
    createStyles({
      productFeatureContainer: {
        
        overflowX: "visible",
      },
      productFeatureTable: {
        width: "100%",
        borderCollapse: "collapse",
        textAlign: "center",
        margin: "auto",
        tableLayout: "fixed",
      },
      emptyHeader: {
        textAlign: "left",
        padding: "19px",
        fontWeight: "bold",
        fontSize: "16px",
        backgroundColor: "#f4f4f4",
      },
      productHeader: {
        position: "relative",
        textAlign: "center",
        padding: "16px",
        fontWeight: "normal",
        backgroundColor: "#F5F5F5",
        transition: "box-shadow 0.3s ease, background-color 0.3s ease",
        zIndex: "auto",
        borderBottom: "none",
        boxSizing: "border-box",
        height: "260px",
      },
      hoveredColumn: {
        backgroundColor: "#FFFFFF",
        boxShadow:
          "rgb(5, 167, 168) 1px -1px 1px 1px, rgba(5, 167, 168, 1) -3px -5px 9px -3px",
        zIndex: 1,
        borderTopLeftRadius:'16px',
        borderTopRightRadius:'16px',
      },
      productName: {
        height: "46px",
      },
      productTitle: {
        fontFamily: "Nunito",
        fontWeight: "800",
        fontSize: "24px",
        color: "#05A7A8",
      },
      productPrice: {
        marginTop:'10px',
        marginBottom:'10px'
      },
      productPriceText: {
        fontFamily: "Nunito",
        fontWeight: "700",
        fontSize: "20px",
        color: "#4A5362",
      },
      productDescription: {
        height: "57px",
      },
      productDescriptionText: {
        fontFamily: "Nunito",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#757575",
      },
      featureTitleCell: {
        width: "216px",
        fontFamily: "Nunito",
        lineHeight: "16.37px",
        textAlign: "left",
        padding: "8px",
        fontWeight: "400",
        fontSize: "12px",
        borderBottom: "1px solid #05A7A8",
      },
      featureCell: {
        textAlign: "center",
        padding: "8px",
        borderBottom: "1px solid #05A7A8",
        backgroundColor: "transparent",
        transition: "box-shadow 0.3s ease, background-color 0.3s ease",
      },
      hoveredFeatureCell: {
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 4px 10px rgba(5, 167, 168, 1)",
       
      },
      hoveredFeatureCell1: {
       borderBottomLeftRadius:'16px',
       borderBottomRightRadius:'16px',
       
      },
      featureIcon: {
        width: "22.38px",
        height: "22.77px",
      },
      gridSpacing: {
        margin: theme.spacing(2, 0),
        [theme.breakpoints.down("md")]: {
          margin: theme.spacing(1, 0),
        },
      },
      whiteCard: {
        padding: theme.spacing(2),
        borderRadius: theme.spacing(2),
        backgroundColor: "white",
        boxShadow: "rgba(149, 157, 165, 0.35) 0px 0px 16px",
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(1.5),
        },
      },
      lightGreyCard: {
        padding: theme.spacing(2),
        borderRadius: theme.spacing(2),
        backgroundColor: "#f8f8f8",
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(1.5),
        },
      },
      checkboxGridCards: {
        padding: "0.5rem",
        borderRadius: "1rem",
        backgroundColor: "#f8f8f8",
        flexBasis: "48%",
        maxWidth: "48%",
        marginTop: "1rem",
        paddingLeft: "2rem",
        border: `2px solid #f8f8f8`,
        display: "flex",
        alignItems: "center",
      },
      selectedCheckboxGridCards: {
        padding: "0.5rem",
        borderRadius: "1rem",
        backgroundColor: "#f8f8f8",
        flexBasis: "48%",
        maxWidth: "48%",
        marginTop: "1rem",
        paddingLeft: "2rem",
        border: `2px solid ${theme.palette.primary.main}`,
        display: "flex",
        alignItems: "center",
      },
      darkGreyCard: {
        padding: "1rem",
        borderRadius: "1rem",
        backgroundColor: "#F8F8F8",
        paddingLeft: "2rem",
      },
      darkGreyCardWithoutPadding: {
        borderRadius: "12px",
        backgroundColor: "#f6f6f6",
      },
      title: {
        fontFamily: "Nunito Sans",
        fontSize: "1.75rem",
        color: "#54A4A6",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        fontWeight: '700'
      },
      blueTitle: {
        fontFamily: "Nunito Sans",
        fontSize: "1.75rem",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        marginBottom: theme.spacing(1),
        color: theme.palette.text.blue2,
      },
      subTitle: {
        fontFamily: "Nunito Sans",
        fontSize: "1rem",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        color: theme.palette.text.blue2,
      },
      greyCardTitle: {
        fontFamily: "Nunito Sans",
        fontSize: "1.75rem",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        color: theme.palette.text.blue2,
      },
      clientListColorBar: {
        position: "absolute",
        left: 0,
        top: "30%",
        width: "4px",
        height: "40%",
        backgroundColor: theme.palette.primary.main,
      },
      greenCard: {
        padding: theme.spacing(2, 4),
        borderRadius: "1rem",
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
      },
      blueCard: {
        padding: theme.spacing(2, 4),
        borderRadius: "1rem",
        backgroundColor: theme.palette.text.blue2,
        color: "#fff",
      },
      twoEqualCards: {
        flexBasis: "98%",
        maxWidth: "98%",
      },
      smallWhiteCards: {
        flexBasis: "96%",
        maxWidth: "96%",
        margin: theme.spacing(1, 0),
        [theme.breakpoints.down("md")]: {
          margin: theme.spacing(1, 0),
        },
      },
      smallWhiteCardsHalfWidth: {
        flexBasis: "48.5%",
        maxWidth: "48.5%",
        margin: theme.spacing(1, 0),
        [theme.breakpoints.down("md")]: {
          margin: theme.spacing(1, 0),
        },
      },
      smallCardsText: {
        fontFamily: "Nunito Sans",
        fontSize: "18px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
      },
      subscriptionBoxHeading: {
        fontFamily: "Nunito Sans",
        fontSize: "1.5rem",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        marginBottom: theme.spacing(2),
      },
      subscriptionBoxSubHeading: {
        fontFamily: "Nunito Sans",
        fontSize: "1.3rem",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
      },
      subscriptionBoxBodyText: {
        fontFamily: "Nunito Sans",
        fontSize: "1.2rem",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
      },
      packageCard: {
        flexBasis: "48%",
        maxWidth: "48%",
        marginTop: "3rem",
      },
      engagePackage: {
        flexBasis: "48%",
        maxWidth: "48%",
        marginTop: "3rem",
        border: `1px solid transparent`,
        borderRadius: "1rem",
      },
      advicePackage: {
        flexBasis: "48%",
        maxWidth: "48%",
        marginTop: "3rem",
        border: `1px solid transparent`,
        borderRadius: "1rem",
      },
      engagePackageSelected: {
        flexBasis: "48%",
        maxWidth: "48%",
        marginTop: "3rem",
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: "1rem",
      },
      advicePackageSelected: {
        flexBasis: "48%",
        maxWidth: "48%",
        marginTop: "3rem",
        border: `1px solid ${theme.palette.text.blue2}`,
        borderRadius: "1rem",
      },
      applyChangesButton: {
        height: "4.4rem",
        fontSize: "1.2rem",
      },
      priceTextLarge: {
        fontFamily: "Nunito",
        fontSize: "23px",
        color: theme.palette.primary.main,
      },
      priceTextSmall: {
        fontFamily: "Nunito Sans",
        fontSize: "1.2rem",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        fontWeight: 800,
        color: theme.palette.text.secondary,
      },
      appBarStyles: {
        width: "100%",
        top: "auto",
        bottom: 0,
        backgroundColor: " #ffffff",
        padding: "0.5rem 0",
      },
      addonCheckboxLabel: {
        color: theme.palette.text.secondary,
        fontSize: "1.2rem",
      },
      buttonClass: {
        height: "4.2rem",
        fontSize: "1.2rem",
        borderRadius: " 32px",
        background: "#05A7A8",
        width: '200px'
      },
      buttonClass1: {
        height: '56px',
        fontSize: '16px',
        background: '#05A7A8',
        backgroundColor: 'rgb(5, 167, 168)',
        borderRadius: '100px',
        width: '235px',
        textTransform: 'none'
      },
      CancelbuttonClass: {
        color: " #DE1867",
        height: " 56px",
        fontSize: '12px',
        width: " 147px",
        fontWeight: 400,
        textTransform: 'none',
      },
      addSignatureButton: {
        padding: "0 1rem",
        height: "auto"
      },
      planSelectionContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent:'space-around',
        width:'100%',
        margin: '20px',
        marginLeft:'30px',
        borderRadius: '10px'
      },
      label: {
        fontSize: '16px',
        color: '#333',
      },
      root: {
        display: 'flex',
        justifyContent: 'center',
        padding: '50px 20px',
        backgroundColor: '#f9f9f9',
        height: '100vh',
        overflow: 'hidden',
      },
      savingPercent: {
        fontSize: '12px',
        fontWeight: '800',
        marginTop:'5px',
        color:'#05A7A8',
        marginRight:'5px'
      },
      buttonGroup: {
        display: 'flex',
        justifyContent: 'center',
        borderRadius: "27px",
        border: "1px solid #05A7A8",
        width: "131px",
        height: "28px",
        alignItems: 'center',
        
      },
      selected: {
        flex: 1,
        padding: "10px 0",
        border: "none",
        borderRadius: "100px",
        cursor: "pointer",
        fontSize: "12px",
        textTransform: "none",
        backgroundColor: "#05A7A8",
        height: "100%",
        color: "#fff",
        '&:hover': {
          backgroundColor: "#05A7A8",
        },
      },
      notSelected: {
        flex: 1,
        padding: "10px 0",
        border: "none",
        borderRadius: "100px",
        cursor: "pointer",
        fontSize: "12px",
        textTransform: "none",
        height: "100%",
        background: '#fff',
        '&:hover': {
          backgroundColor: '#F5F5F5',
        },
      },
      plansButtons: {
        background: '#F5F5F5',
        color: '#00c4cc',
        fontSize:'16px',
        fontWeight:800,
        margin: '10px',
        padding: '25px 27px',
        borderRadius: '100px',
        fontWeight: 'bold',
        border: '2px solid #F5F5F5',
        textTransform:'none',
        "&:hover": {
          backgroundColor: "#05A7A8",
        },
      },
      selectedPlanButton: {
        backgroundColor: '#05A7A8',
        color: '#fff',
      },
      flexContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '48px',
        justifyContent: 'center',
        backgroundColor: "#F5F5F5",
        borderRadius: '12px',
        padding: '24 px 32px 24px 32px'
      },
      table: {
        width: "100%",
        borderCollapse: "collapse",
        textAlign: "center",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#ffffff",
        marginTop: "20px",
      },
    }),
  { withTheme: true }
)(MySubscriptionsWithPermissions);

// Customizable Area End