//@ts-nocheck
import React from "react";
import {
    withStyles,
    Grid,
    createStyles,
    Button,
    Typography,
    MenuItem,
    TextField,
    Box
} from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialogBox from "../../../../components/src/withDialog.web";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { NumericFormat } from "react-number-format";
import InputField from "../../../../components/src/InputFieldPhaseTwo.web";


const validationfeatureSchema = Yup.object().shape({
    bumpName: Yup.string().required("Bump Name is required.").matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed for Feature Name."),
    bumpdescription: Yup.string().required("Bump Description is required.").matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed for Feature Name."),
    bumpfor: Yup.string().required("Bump Details is required.").matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed for Feature Name."),
    bumptypeOptions: Yup.string().required("Bump type should be Selected"),
    bumpcost: Yup.string().required("Price is required")
});
export class AddPricebumpDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            initialValues: { bumpName: "", bumpdescription: "", bumpfor: "", bumpcost: null },
            bumptypeOptions: ["Individual", "Business"],
        };
    }

    render() {
        const { classes } = this.props;
        const { initialValues, bumptypeOptions } = this.state;
        return (
            <Formik
                initialValues={initialValues}
                validationSchema={validationfeatureSchema}
                onSubmit={(values, { resetForm }) => {
                    resetForm();
                    this.props.onSubmit(values);
                    this.setState({
                        bumpcost: null,
                    })
                }}
            >
                {({ isValid, dirty }) => {
                    return (
                        <Form>
                            <Grid container spacing={2} style={{ width: "30vw", padding: "1rem 1rem 2rem 1rem" }}>
                                <Grid item xs={12}>
                                    <Typography style={{
                                        margin: "0"
                                    }} variant="h3" align="center" className={classes.dialogHeading}>
                                        Add Pricing Bump
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="first Baseline">
                                        <Typography style={{
                                            width: "70%",
                                            textAlign: "center"
                                        }}>If the client</Typography>
                                        <Field
                                            component={InputField}
                                            name="bumpdescription"
                                            id="bumpdescription"
                                            fullWidth
                                        >
                                        </Field>

                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="first Baseline">
                                        <Typography style={{
                                            width: "100%",
                                            textAlign: "center"
                                        }}> increase their total fee by ($)</Typography>
                                        <Field component={InputField} name="bumpcost" >
                                            {({ field, form }) => (
                                                <NumericFormat
                                                    {...field}
                                                    id="bumpcost"
                                                    customInput={TextField}
                                                    fullWidth
                                                    thousandSeparator
                                                    allowNegative={false}
                                                    onValueChange={(values) => {
                                                        form.setFieldValue("bumpcost", values.floatValue || "");
                                                    }}
                                                    value={form.values.bumpcost}
                                                    InputProps={{
                                                        style: {
                                                            padding: "8px",
                                                            marginTop: "10px"
                                                        },
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="first Baseline">
                                        <Typography style={{
                                            width: "70%",
                                            textAlign: "center"
                                        }}>for each</Typography>
                                        <Field
                                            component={InputField}
                                            name="bumpfor"
                                            id="bumpfor"
                                            fullWidth
                                        >
                                        </Field>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="first Baseline">
                                        <Typography style={{
                                            width: "70%",
                                            textAlign: "center"
                                        }}>Bump package name </Typography>
                                        <Field
                                            component={InputField}
                                            name="bumpName"
                                            id="bumpName"
                                            fullWidth
                                        /></Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="first Baseline">
                                        <Typography style={{
                                            width: "70%",
                                            textAlign: "center"
                                        }}>Bump Type</Typography>
                                        <Field
                                            component={InputField}
                                            name="bumptypeOptions"
                                            select
                                            fullWidth
                                        >
                                            {bumptypeOptions.map((category, index) => (
                                                <MenuItem key={index} value={category} id="bumptypeOptions" >
                                                    {category}
                                                </MenuItem>
                                            ))}
                                        </Field></Box>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="center">
                                        <Button
                                            id="submitform"
                                            variant="contained"
                                            fullWidth
                                            type="submit"
                                            color="primary"
                                            style={{
                                                marginTop: "5px",
                                                width: "50%"
                                            }}
                                            disabled={!(isValid && dirty)}
                                        >
                                            Submit
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Form>)
                }}
            </Formik>
        );
    }
}

const AddPricebumpDialogWithRouter = withRouter(AddPricebumpDialog);
const AddPricebumpDialogWithToast = withToast(AddPricebumpDialogWithRouter);
const AddPricebumpDialogWithLoader = withLoader(
    AddPricebumpDialogWithToast
);
const AddPricebumpDialogWithAlertBox = withAlertBox(
    AddPricebumpDialogWithLoader
);
const AddPricebumpDialogWithDialogBox = withDialogBox(
    AddPricebumpDialogWithAlertBox
);

export default withStyles((theme) =>
    createStyles({
        fileInput: {
            display: "none",
        },
        crossOverImage: {
            right: "-5px",
            top: "-5px",
            position: "absolute",
            backgroundColor: "red",
            color: "white",
            padding: "3px",
            width: "16px",
            height: "16px",
            borderRadius: "50%",
            cursor: "pointer",
        },
        dialogHeading: {
            fontSize: "2rem",
            fontWeight: "bold",
            margin: "1.5rem 0",
            textAlign: "center",
            color: theme.palette.primary.main,
        },

        dialogsubHeading: {
            fontSize: "1.5rem",
            fontWeight: "bold",
            margin: "1.5rem 0",
            textAlign: "center",
        },
        uploadNowButton: {
            margin: "1.5rem 0px",
            width: "40%",
        },
        formControl: {
            margin: theme.spacing(1),
        },
        amoutInputWidth: {
            width: "60%",
            border: "1px solid",
            borderRadius: "0",
            marginBottom: "4px",
        },
        InputPadding: {
            padding: "5px",
        },
        adornedEnd: {
            paddingRight: "6px",
        },
    })
)(AddPricebumpDialogWithDialogBox);
