//@ts-nocheck
import React from "react";
import NewUserDashboardController, {
  Props,
} from "./NewUserDashboardController.web";
import {
  Typography,
  Button,
  Grid,
  Box,
  withStyles,
  createStyles,
  AppBar,
  Toolbar,
  Divider,
  Hidden,
  Avatar,
  InputAdornment,
  TextField,
  Collapse,
  Menu,
  MenuItem,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import ClearIcon from "@material-ui/icons/Clear";
import { logo, profileImage } from "./assets";
import { withRouter, Route, Switch, Redirect } from "react-router";
import { NavLink } from "react-router-dom";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import withConfirmBox from "../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import SearchIcon from "@material-ui/icons/Search";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import SettingsIcon from "@material-ui/icons/Settings";
import Modal from "@material-ui/core/Modal";
import NewDashboard from "./NewDashboard.web";
import moment from "moment";
//Client List Imports
import ClientsListContainer from "./ClientList/ClientsListContainer.web";
import ClientDetailsContainer from "./ClientList/ClientDetailsContainer.web";
//firm Performance page imports
import FirmPerformanceSurvey from "./firmPerformance/FirmPerformanceSurvey.web";
import FirmPerformanceOnboarding from "./firmPerformance/FirmPerformanceOnboarding.web";
import FirmPerformanceProposals from "./firmPerformance/FirmPerformanceProposals.web";
import FirmPerformanceRevenue from "./firmPerformance/FirmPerformanceRevenue.web";
import FirmPerformanceChurns from "./firmPerformance/FirmPerformanceChurns.web";

//settings page imports
import AutomaticReminders from "./Settings/AutomaticReminders.web";
import ClientConversionSurveySettings from "./Settings/ClientConversionSurveySettings.web";
import ManageDeliveryChecklist from "./Settings/ManageDeliveryChecklist.web";
import CreateOnboardingChecklist from "./Settings/CreateOnboardingChecklist.web";

import ProfilePhaseTwo from "./ProfileP2.web";
import MySubscriptions from "./MySubscriptions.web";
import ChangePassword from "./ChangePassword.web";
import ThirdPartyIntegration from "./Settings/ThirdPartyIntegration.web";
import DocumentTemplates from "./Settings/DocumentTemplates.web";
import AddNewTemplate from "./Settings/AddNewTemplate.web";
import PastRoadmapsWeb from "./roadmap/PastRoadmaps.web";
import StartRoadMap from "./roadmap/StartRoadMap.web";
import ManageUsersWeb from "./Settings/ManageUsers.web";
import CreateAndManageUserWeb from "./Settings/CreateAndManageUser.web";
import PricingContainerWeb from "./Settings/PricingContainer.web";
import Branding from "./Settings/Branding.web";
import withPermissions from "../../../components/src/withPermissions.web";
import ManageUsersCommonDialogWeb from "./Settings/ManageUsersCommonDialog.web";
import CustomPackageCreationContainer from "./CustomPackage/CustomPackageCreationContainer.web";
import IntercomStep from "./Settings/IntercomStep.web";
import HelpCenter, { SectionWithRouter, Show_collectionWithRouter } from "./Settings/HelpCenter.web";
import MyNewSubscription from "./MyNewSubscription.web";
const drawerWidth = 240;

export class NewUserDashboard extends NewUserDashboardController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {

    this.setState(
      {
        activeSideLink: this.props.history.location.pathname,
      },
      async () => {
        const token = await StorageProvider.get("authToken");
        const accountId = await StorageProvider.get("accountId");
        const currentUser = await StorageProvider.get("currentUser");
        this.unlisten = this.props.history.listen((location) => {
          if (location.pathname === "/home/roadmaps/createRoadMap" && !this.state.hasUpdated) {
            this.setState(
              {
                activeSideLink: "/home/roadmaps",
                isCreateRoadMap: true,
                hasUpdated: true,
              },
              () => {
                this.createNewRoadmap();
              }
            );
          } else if (location.pathname !== "/home/roadmaps/createRoadMap") {
            this.setState({ hasUpdated: false });
          }
        });
        this.unlisten = this.props.history.listen((location, action) => {
          this.setState({
            searchValue: "",
          });
        });
        if (token) {
          this.setState(
            {
              token: token,
              accountId: accountId,
              subscriptions:
                this.state.activeSideLink.indexOf("subscription") > -1
                  ? true
                  : false,
              currentUser: currentUser ? JSON.parse(currentUser) : null,
            },
            () => {
              this.getProfile();
            }
          );
        }
      }
    );
  }

  componentWillUnmount() {
    this.unlisten();
  }

  confirmNavigation = (targetPath) => {
    if (targetPath === "/home/roadmaps/createRoadMap") {
      const confirmLeave = window.confirm("You have unsaved changes. Do you really want to leave?");
      return confirmLeave;
    }
    return true;
  };

  getAvatarImage = () => {
    let currentUser = this.state.currentUser?.data?.attributes;
    if (currentUser?.employee_permission?.length > 0) {
      return currentUser?.employee_profile_image_url || profileImage
    }
    return currentUser?.profile_image_url || profileImage
  }

  openLogoutDialog = () => {
    this.props
      .openDialogBox({
        dataToPass: {
          description: "Are you sure you want to sign out from application?",
          yesButtonText: "Yes",
          noButtonText: "No",
        },
        width: "45%",
        renderedComponent: ManageUsersCommonDialogWeb,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data === "Yes") {
          await StorageProvider.clearStorage();
          localStorage.clear();
          window.location.replace("/")
        }
      });
  };

  handleDrawerToggle = () => {
    this.setState({
      mobileOpen: !this.state.mobileOpen,
    });
  };

  handleProfileIconClick = (event) => {
    this.setState({
      anchorElement: event.currentTarget,
    });
  };

  handleProfileIconClose = () => {
    this.setState({
      anchorElement: null,
    });
  };

  handleMenuItemClick = (event, index, optionUrl) => {
    if (!this.confirmNavigation(this.props.history.location.pathname)) {
      event.preventDefault();
      return;
    }
    this.setState(
      {
        selectedMenuItemIndex: index,
        anchorElement: null,
        subscriptions: optionUrl.indexOf("subscriptions") > -1,
        activeSideLink: optionUrl
      },
      () => {
        this.props.history.push(optionUrl);
      }
    );
  };

  checkSubscription = () => {
    const { currentUser, currentSubscription } = this.state;
    let trialPeriodEndTime;
    const currentTime = moment(new Date(new Date().setHours(0, 0, 0)));
    if (currentUser) {
      let createdAt = new Date(
        new Date(currentUser?.data?.attributes?.created_at).setHours(23)
      );
      trialPeriodEndTime = moment(createdAt).add(14, "days");
    }
    let openModal = false;
    let trialPeriod = trialPeriodEndTime?.diff(currentTime, "days");
    let currentSubscriptionStatus = currentSubscription?.subscription_status;

    if (trialPeriod < 1) {
      if (
        !currentSubscriptionStatus ||
        (currentSubscriptionStatus && currentSubscriptionStatus != "active")
      ) {
        openModal = true;
      }
    }
    return { openModal, trialPeriod };
  };

  getProfileOptions = () => {
    return [
      {
        title: "My Profile",
        url: "/home/profile",
      },
      {
        title: "My Subscriptions",
        url: "/home/subscriptions",
      },
      {
        title: "Payment Method",
        url: "/home/billings",
      },
      {
        title: "Change Password",
        url: "/home/changePassword",
      },
    ];
  };

  getSideBarLinks = () => {
    return [
      {
        title: "Home",
        url: "/home/dashboard",
      },
      {
        title: "Client List",
        url: "/home/clientsList",
      },
      {
        title: "Roadmaps",
        url: "/home/roadmaps",
        subLinks: [
          {
            title: "Create a Roadmap",
            url: "/home/roadmaps/createRoadMap",
          },
          {
            title: "View Past Roadmaps",
            url: "/home/roadmaps/pastRoadmaps",
          },
        ],
      },
      {
        title: "Workflow",
        url: "/home/workflow",
      },
      {
        title: "Activities",
        url: "/home/activities",
      },
      {
        title: "Firm Performance",
        url: "/home/firmPerformance",
        subLinks: [
          {
            title: "Revenue",
            url: "/home/firmPerformance/revenue",
          },
          {
            title: "Onboarding",
            url: "/home/firmPerformance/onboarding",
          },
          {
            title: "Proposals",
            url: "/home/firmPerformance/proposals",
          },
          {
            title: "Survey",
            url: "/home/firmPerformance/survey",
          },
          {
            title: "Churn",
            url: "/home/firmPerformance/churns",
          },
        ],
      },
      {
        title: "Resources",
        id: "Resources",
        url: "https://portal.smartpath.co/users/sign_in",
      },
      {
        title: "Setup",
        url: "/home/settings",
        subLinks: [
          {
            title: "Pricing Customization",
            url: "/home/settings/pricing",
          },
          {
            title: "Branding",
            url: "/home/settings/branding",
          },
          {
            title: "Magic Client Pricing Settings",
            url: "/home/settings/magicClientPricingSettings",
            permission: "Manage Survey Settings",
          },
          {
            title: "Onboarding Checklists",
            url: "/home/settings/manageDeliveryChecklist",
          },
          {
            title: "Integrations",
            url: "/home/settings/integrations",
          },
          {
            title: "Document Templates",
            url: "/home/settings/documentTemplates",
          },
          {
            title: "Auto-Reminders",
            url: "/home/settings/reminder",
          },
          {
            title: "Manage Users",
            url: "/home/settings/manageUsers",
          }
        ],
      },
      {
        title: "Help Center",
        id: 'Help_Center',
        url: "https://help.smartpath.co/en/",
      },
    ];
  };

  renderSubLink = (classes, subLink) => {
    return (
      <Grid item xs={12} key={subLink.title}>
        <NavLink
          to={subLink.url}
          onClick={(event) => {
            if (!this.confirmNavigation(this.props.history.location.pathname)) {
              event.preventDefault();
              return;
            }
            if (subLink.url === "/home/roadmaps/createRoadMap") {
              event.preventDefault();
              this.setState(
                {
                  isCreateRoadMap: true,
                  subscriptions: false,
                },
                () => {
                  this.createNewRoadmap();
                }
              );
            } else {
              this.setState({
                isCreateRoadMap: false,
                subscriptions: false,
              });
            }
          }}
          className={classes.subLink}
          activeClassName={classes.activeSubLink}
        >
          {subLink.title}
        </NavLink>
      </Grid>
    );
  };

  renderNavlinks = (classes, archive) => {
    return (
      <>
        <Grid
          item
          xs={12}
          md={12}
          className={classes.sideStyleBar}
          style={{
            position: "relative",
          }}
          key={archive.title}
        >
          <NavLink
            to={archive.url}
            className={classes.sideLink}
            activeClassName={classes.activeSideLink}
            id={archive.id}
            onClick={(event) => {
              if (!this.confirmNavigation(this.props.history.location.pathname)) {
                event.preventDefault();
                return;
              }
              this.setState({
                activeSideLink: archive.url,
                isCreateRoadMap: false,
                subscriptions: false,
              });
              this.openNewTab(event, archive)
            }}
          >
            {archive.title}
          </NavLink>
        </Grid>
        <Collapse
          timeout={500}
          mountOnEnter
          unmountOnExit
          in={this.state.activeSideLink.includes(archive.url)}
          style={{
            width: "100%",
          }}
        >
          {this.state.activeSideLink.includes(archive.url) &&
            archive.subLinks &&
            archive.subLinks.length > 0
            ? archive.subLinks.map((subLink) => {
              return this.renderSubLink(classes, subLink);
            })
            : null}
        </Collapse>
      </>
    );
  };

  renderSideBar = (classes, trialPeriod) => {
    const sideBarLinks = this.getSideBarLinks();
    const { currentSubscription } = this.state;

    return (
      <Grid item xs={2} className={classes.sideBar}>
        {this.state.currentUser && <IntercomStep />}
        <Grid
          container
          justifyContent="center"
          className={classes.sideBarContain}
        >
          <Grid item md={8}>
            <img src={logo} alt="logo" style={{ width: "100%" }} />
          </Grid>
          <Grid item xs={12} md={12} className={classes.sideBarLinksContainer}>
            <Grid container justifyContent="center" alignItems="center">
              {/* Desktop View */}
              {sideBarLinks.map((archive) => {
                return this.renderNavlinks(classes, archive);
              })}
            </Grid>
          </Grid>
          {(!currentSubscription && trialPeriod > 0) && (
            <Grid item xs={12} className={classes.trialPeriodBoxContainer}>
              <Grid
                container
                direction="column"
                className={classes.trialPeriodBox}
              >
                <Typography className={classes.trialTitle}>
                  {trialPeriod} days left
                </Typography>
                <Typography className={classes.trialSubtitle}>
                  in Trial Period
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };
  renderNoSubscriptionModal = (classes, openModal) => {
    return (
      <Modal
        open={openModal && this.state.openModal}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
          }}
          className={classes.trialpaper}
        >
          <Grid container>
            <Grid xs={12} item>
              <Typography
                color="error"
                variant="h2"
                id="simple-modal-description"
              >
                Your trial period has been ended.
              </Typography>
              <Typography
                color="textSecondary"
                variant="h4"
                id="simple-modal-description"
              >
                To continue working with{" "}
                <Typography component={"span"} color="primary">
                  Smartpath.co
                </Typography>{" "}
                it is necessary to take a subscription of our product
              </Typography>
            </Grid>
            <Grid xs={12} item>
              <Grid container justifyContent="flex-end">
                <Button
                  color="primary"
                  onClick={() => {
                    this.setState({ openModal: false }, () => {
                      this.props.history.push("/home/subscriptions");
                    });
                  }}
                  variant="contained"
                >
                  Buy subscription
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Modal>
    );
  };

  renderAppBar = (classes) => {
    const profileMenuOptions = this.getProfileOptions();
    return (
      <Hidden smDown>
        <AppBar
          position="sticky"
          color="default"
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar className={classes.toolbar}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={7} md={4} className={[classes.gridSpacing]}>
                <TextField
                  variant="outlined"
                  placeholder="Type and press ENTER to search"
                  fullWidth
                  value={this.state.searchValue}
                  onChange={(event) => {
                    this.setState({
                      searchValue: event.target.value,
                    });
                  }}
                  onKeyDown={(ev) => {
                    if (ev.key === "Enter") {
                      ev.preventDefault();
                      this.searchClient(ev.target.value);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="start"
                        style={{
                          paddingRight: 0,
                        }}
                      >
                        {this.state.searchValue ? (
                          <ClearIcon
                            onClick={() => {
                              this.setState(
                                {
                                  searchValue: "",
                                },
                                () => {
                                  this.searchClient();
                                }
                              );
                            }}
                          />
                        ) : (
                          <SearchIcon />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4} md={3} className={[classes.gridSpacing]}>
                <Grid
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  className={classes.headerIconsGap}
                >
                  <NotificationsNoneIcon />
                  <SettingsIcon />
                  <Avatar
                    alt="profileImage"
                    src={this.getAvatarImage()}
                    className={classes.avatar}
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    onClick={this.handleProfileIconClick}
                  />
                  <Menu
                    id="customized-menu"
                    anchorEl={this.state.anchorElement}
                    keepMounted
                    open={Boolean(this.state.anchorElement)}
                    onClose={this.handleProfileIconClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    classes={{
                      paper: classes.menuPaper,
                    }}
                  >
                    {profileMenuOptions.map((option, index) => (
                      <MenuItem
                        key={option.title}
                        disabled={index === 2}
                        onClick={(event) =>
                          this.handleMenuItemClick(event, index, option.url)
                        }
                        classes={{
                          root: classes.menuItemRoot,
                        }}
                      >
                        {option.title}
                      </MenuItem>
                    ))}
                    <MenuItem
                      onClick={() => {
                        this.handleProfileIconClose();
                        this.openLogoutDialog();
                      }}
                      classes={{
                        root: classes.menuItemRoot,
                      }}
                    >
                      Sign Out
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Hidden>
    );
  };

  openNewTab = (event, archive) => {
    if (archive.title === "Resources") {
      event.preventDefault();
      window.open(archive.url, "_blank", "noopener");
    }
    if (archive.title === "Help Center") {
      event.preventDefault();
      window.open(archive.url, "_blank", "noopener");
    }
  }

  render() {
    const { classes } = this.props;
    const { openModal, trialPeriod } = this.checkSubscription();
    const sideBarLinks = this.getSideBarLinks();
    return (
      <>
        {this.state.currentUser && <IntercomStep />}
        <Hidden mdUp>
          <AppBar
            position="sticky"
            color="default"
            elevation={1}
            className={classes.appBar}
          >
            <Toolbar className={classes.toolbar}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                edge="start"
                onClick={this.handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Box className={classes.toolbarLogo}>
                <img src={logo} alt="logo" style={{ width: "15%" }} />
              </Box>

              <Grid
                item
                xs={4}
                md={5}
                container
                justifyContent="flex-end"
                alignItems="center"
                className={[classes.gridSpacing]}
              >
                <Grid item xs={4} md={1}>
                  <NotificationsNoneIcon />
                </Grid>
                <Grid item xs={4} md={1}>
                  <SettingsIcon />
                </Grid>
                <Grid item xs={4} md={1}>
                  <Avatar
                    alt="profileImage"
                    src={this.getAvatarImage()}
                    className={classes.avatar}
                  />
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </Hidden>
        <Grid container>
          <Hidden smDown>{this.renderSideBar(classes, trialPeriod)}</Hidden>

          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              anchor={"left"}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <>
                <IconButton
                  onClick={this.handleDrawerToggle}
                  className={classes.closeMenuButton}
                >
                  <CloseIcon />
                </IconButton>
                <Grid
                  container
                  justifyContent="space-around"
                  className={classes.sideBarContain}
                >
                  <Grid item xs={12} md={9}>
                    <Grid container justifyContent="space-around">
                      {sideBarLinks.map((archive) => {
                        return (
                          <>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              className={classes.sideStyleBar}
                              style={{
                                position: "relative",
                              }}
                              key={archive.title}
                            >
                              <NavLink
                                to={archive.url}
                                className={classes.sideLink}
                                activeClassName={classes.activeSideLink}
                                id={archive.id}
                                onClick={(event) => {
                                  this.setState({
                                    activeSideLink: archive.url,
                                    isCreateRoadMap: false,
                                  });
                                  this.openNewTab(event, archive)
                                }}
                              >
                                {archive.title}
                              </NavLink>
                            </Grid>
                            <Collapse
                              timeout={500}
                              mountOnEnter
                              unmountOnExit
                              in={this.state.activeSideLink.includes(
                                archive.url
                              )}
                              style={{
                                width: "100%",
                              }}
                            >
                              {this.state.activeSideLink.includes(
                                archive.url
                              ) &&
                                archive.subLinks &&
                                archive.subLinks.length > 0
                                ? archive.subLinks.map((subLink) => (
                                  <Grid item xs={12} key={subLink.title}>
                                    <NavLink
                                      to={subLink.url}
                                      onClick={(event) => {
                                        if (
                                          subLink.url ===
                                          "/home/roadmaps/createRoadMap"
                                        ) {
                                          event.preventDefault();
                                          this.setState(
                                            {
                                              isCreateRoadMap: true,
                                            },
                                            () => {
                                              this.createNewRoadmap();
                                            }
                                          );
                                        } else {
                                          this.setState({
                                            isCreateRoadMap: false,
                                          });
                                        }
                                      }}
                                      className={classes.subLink}
                                      activeClassName={classes.activeSubLink}
                                    >
                                      {subLink.title}
                                    </NavLink>
                                  </Grid>
                                ))
                                : null}
                            </Collapse>
                          </>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>

                <Divider variant="middle" />
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item md={6}>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() => {
                        this.props.history.push("/");
                      }}
                    >
                      Back to Home
                    </Button>
                  </Grid>
                </Grid>
              </>
            </Drawer>
          </Hidden>
          {this.renderNoSubscriptionModal(classes, openModal)}
          <Grid item md={10} xs={12}>
            {this.renderAppBar(classes)}
            <Grid
              container
              className={
                this.state.subscriptions
                  ? classes.subscriptionroot
                  : classes.root
              }
            >
              <Grid item xs={12} md={12} className={classes.rootChildContainer}>
                <Switch>
                  <Route path="/home/customPackageCreation" exact>
                    <CustomPackageCreationContainer />
                  </Route>

                  <Route path="/home/dashboard" exact>
                    <NewDashboard />
                  </Route>
                  <Route path="/home/clientsList/details">
                    <ClientDetailsContainer />
                  </Route>
                  <Route path="/home/clientsList">
                    <ClientsListContainer />
                  </Route>

                  {/* firm performance sub routes */}

                  <Route exact path="/home/firmPerformance/survey">
                    <FirmPerformanceSurvey />
                  </Route>
                  <Route exact path="/home/firmPerformance/onboarding">
                    <FirmPerformanceOnboarding />
                  </Route>
                  <Route exact path="/home/firmPerformance/proposals">
                    <FirmPerformanceProposals />
                  </Route>
                  <Route exact path="/home/firmPerformance/revenue">
                    <FirmPerformanceRevenue />
                  </Route>
                  <Route exact path="/home/firmPerformance/churns">
                    <FirmPerformanceChurns />
                  </Route>

                  {/* roadmap sub routes */}
                  <Route path="/home/roadmaps/pastRoadmaps" exact>
                    <PastRoadmapsWeb />
                  </Route>
                  <Route path="/home/roadmaps/pastRoadmaps/:id" exact>
                    <StartRoadMap />
                  </Route>
                  <Route path="/home/roadmaps/createRoadMap" exact>
                    <StartRoadMap key="start-roadmap-key-4K9X7P2Y5Z" />
                  </Route>

                  {/* account sub routes */}
                  <Route path="/home/profile">
                    <ProfilePhaseTwo />
                  </Route>
                  <Route path="/home/firmDetails">
                    {/* <ProfilePhaseTwo /> */}
                  </Route>
                  {/* <Route path="/home/subscriptions">
                    <MySubscriptions />
                  </Route> */}
                  <Route path="/home/subscriptions">
                    <MyNewSubscription/>
                  </Route>
                  <Route exact path="/home/subscriptions/stripePaymentSuccess">
                    <MySubscriptions isPaymentSuccess={true} />
                  </Route>
                  <Route exact path="/home/subscriptions/stripePaymentFailure">
                    <MySubscriptions isPaymentSuccess={false} />
                  </Route>
                  <Route path="/home/billings">
                    {/* <ProfilePhaseTwo /> */}
                  </Route>
                  <Route path="/home/changePassword">
                    <ChangePassword />
                  </Route>

                  {/* settings sub routes */}

                  {/* <Route path="/home/settings/">
                    <Setup />
                  </Route> */}



                  <Route path="/home/helpcenter" exact>
                    <HelpCenter />
                  </Route>
                  <Route path="/home/helpcenter/collection" exact>
                    <Show_collectionWithRouter />
                  </Route>
                  <Route path="/home/helpcenter/collection/showsection" exact>
                    <SectionWithRouter />
                  </Route>
                  <Route path="/home/settings/pricing">
                    <PricingContainerWeb />
                  </Route>
                  <Route path="/home/settings/reminder">
                    <AutomaticReminders />
                  </Route>
                  <Route path="/home/settings/magicClientPricingSettings">
                    <ClientConversionSurveySettings />
                  </Route>
                  <Route path="/home/settings/manageDeliveryChecklist">
                    <ManageDeliveryChecklist />
                  </Route>
                  <Route path="/home/settings/manageOnboardingChecklist">
                    <CreateOnboardingChecklist type="manage" />
                  </Route>
                  <Route path="/home/settings/createOnboardingChecklist">
                    <CreateOnboardingChecklist type="create" />
                  </Route>
                  <Route path={"/home/settings/integrations"}>
                    <ThirdPartyIntegration />
                  </Route>
                  <Route path={"/home/settings/manageUsers"} exact>
                    <ManageUsersWeb />
                  </Route>
                  <Route
                    path={"/home/settings/manageUsers/createNewUser"}
                    exact
                  >
                    <CreateAndManageUserWeb type="create" />
                  </Route>
                  <Route path={"/home/settings/manageUsers/editUser"} exact>
                    <CreateAndManageUserWeb type="manage" />
                  </Route>
                  <Route path="/home/settings/documentTemplates/addNew">
                    <AddNewTemplate />
                  </Route>

                  <Route path={"/home/settings/documentTemplates"}>
                    <DocumentTemplates />
                  </Route>
                  <Route path="/home/settings/branding">
                    <Branding />
                  </Route>

                  {/* redirects */}
                  <Redirect
                    exact
                    from="/home/firmPerformance"
                    to="/home/firmPerformance/revenue"
                  />
                  <Redirect
                    exact
                    from="/home/roadmaps"
                    to="/home/roadmaps/createRoadMap"
                  />
                  <Redirect
                    exact
                    from="/home/account"
                    to="/home/account/profile"
                  />
                  <Redirect
                    exact
                    from="/home/packages"
                    to="/home/packages/savedCustomPackages"
                  />
                  <Redirect
                    exact
                    from="/home/settings"
                    to="/home/settings/pricing"
                  />
                  <Redirect exact from="/home" to="/home/dashboard" />
                </Switch>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

const NewUserDashboardWithRouter = withRouter(NewUserDashboard);
const NewUserDashboardAlertBox = withAlertBox(NewUserDashboardWithRouter);
const NewUserDashboardLoader = withLoader(NewUserDashboardAlertBox);
const NewUserDashboardToast = withToast(NewUserDashboardLoader);
const NewUserDashboardWithDialog = withDialog(NewUserDashboardToast);
const NewUserDashboardWithPermissions = withPermissions(
  NewUserDashboardWithDialog
);

export default withStyles((theme) =>
  createStyles({
    root: {
      height: "calc(100vh - 10vh)",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: "10px",
        // height: "100px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#F4F4F6",
        // margin: "30px 0px",
        borderRadius: theme.spacing(1),
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#BBBEC6",
        borderRadius: theme.spacing(1),
      },
    },
    subscriptionroot: {
      height: "calc(100vh - 10vh)",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: "10px",
        // height: "100px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#F4F4F6",
        // margin: "30px 0px",
        borderRadius: theme.spacing(1),
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#BBBEC6",
        borderRadius: theme.spacing(1),
      },
    },
    rootChildContainer: {
      margin: theme.spacing(3, 4, 4, 4),
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(2.2),
        margin: 0,
      },
    },
    rootRoadmapChildContainer: {
      margin: theme.spacing(1, 1, 0, 0),
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(2.2),
        margin: 0,
      },
    },
    paper: {
      margin: theme.spacing(8, 8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    cardPaper: {
      margin: theme.spacing(4, 12),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    card: {
      padding: theme.spacing(4),
      borderRadius: "8px",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      height: theme.spacing(25),
      width: "96%",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        width: "98%",
        margin: theme.spacing(2, 0),
      },
    },
    cardImage: {
      padding: theme.spacing(1, 1),
      width: "100%",
      textAlign: "center",
    },
    cardTitle: {
      fontFamily: "Nunito Sans",
      fontSize: "1.25rem",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textAlign: "center",
    },
    MemberTitle: {
      fontFamily: "Nunito Sans",
      fontSize: "1.75rem",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textAlign: "center",
    },
    appBar: {
      // borderBottom: `1px solid ${theme.palette.divider}`,
      width: "100%",
      backgroundColor: " #ffffff",
      // height: "7vh",
      marginTop: "3vh",
    },
    toolbar: {
      flexWrap: "nowrap",
      margin: theme.spacing(0, 4, 0, 4),
      minHeight: 0,
      padding: "10px 0px",
    },
    toolbarLogo: {
      flexGrow: 1,
    },
    sideLink: {
      fontFamily: "Nunito Sans",
      fontSize: "1rem",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 2,
      letterSpacing: "normal",
      color: theme.palette.text.secondary,
      cursor: "pointer",
      textAlign: "left",
      display: "inline-block",
      textDecoration: "none",
      margin: "0.75rem 2.5rem",
    },
    activeSideLink: {
      color: "#ed1867",
      borderRadius: "8px",
      textTransform: "none",
      textDecoration: "none",
      cursor: "pointer",
      margin: "0.75rem 2.5rem",
      fontWeight: "bold",
      display: "inline-block",
      "&:before": {
        content: '""',
        position: "absolute",
        left: 0,
        top: 0,
        backgroundColor: "#ed1867",
        height: "100%",
        width: "4px",
        borderTopRightRadius: "8px",
        borderBottomRightRadius: "8px",
      },
    },
    subLink: {
      fontFamily: "Nunito Sans",
      display: "inline-block",
      fontSize: "0.9rem",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      color: theme.palette.text.secondary,
      cursor: "pointer",
      textAlign: "left",
      textDecoration: "none",
      margin: "0.4rem 0rem",
      marginLeft: "3.5rem",
    },
    activeSubLink: {
      fontSize: "0.9rem",
      display: "inline-block",
      color: theme.palette.primary.main,
      textTransform: "none",
      textDecoration: "none",
      cursor: "pointer",
      margin: "0.4rem 0rem",
      fontWeight: "bold",
      marginLeft: "3.5rem",
    },
    sideBar: {
      position: "relative",
      backgroundColor: "#ffffff",
      margin: "3vh 0vh",
    },
    sideBarContain: {
      height: "94vh",
      // boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 8px",
      // borderTopRightRadius: "12px",
      position: "relative",
      borderRight: `2px solid #dfdee0`,
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    closeMenuButton: {
      marginRight: "auto",
      marginLeft: 0,
    },
    trialPeriodBoxContainer: {
      backgroundColor: "#fff",
      padding: "1rem 3rem",
      zIndex: theme.zIndex.appBar + 1,
      // position: "absolute",
      // bottom: 0,
    },
    trialPeriodBox: {
      backgroundColor: "#f4f4f4",
      borderRadius: "12px",
      padding: "1rem",
    },
    trialTitle: {
      fontFamily: "Nunito Sans",
      fontSize: "1.8rem",
      fontWeight: "bold",
      color: theme.palette.error.main,
      display: "block",
    },
    trialSubtitle: {
      fontFamily: "Nunito Sans",
      fontSize: "1rem",
      color: theme.palette.text.secondary,
    },
    sideBarLinksContainer: {
      overflowY: "scroll",
      maxHeight: "73vh",
      minHeight: "73vh",
      paddingBottom: "2vh",
      "&::-webkit-scrollbar": { display: "none" },
      scrollbarWidth: "none",
    },
    avatar: {
      height: "2rem",
      width: "2rem",
      cursor: "pointer",
      marginRight: "1rem"
    },
    trialpaper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    menuPaper: {
      borderRadius: 0,
      borderTop: `3px solid ${theme.palette.primary.main}`,
      boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
    },
    menuItemRoot: {
      border: "none",
      backgroundColor: "transparent",
      padding: "0.7rem 1.5rem",
      margin: "0.5rem",
      fontSize: "0.9rem",
      fontWeight: 600,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: "white"
      },
      "&.Mui-focusVisible": {
        backgroundColor: "transparent",
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
          color: "white"
        },
      }
    },
    headerIconsGap: {
      gap: "3rem"
    }
  })
)(withConfirmBox(NewUserDashboardWithPermissions));
