//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  createStyles,
  Button,
  FormControl,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Typography,
  MenuItem,
  TextField,
  Box
} from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialogBox from "../../../../components/src/withDialog.web";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { NumericFormat } from "react-number-format";
import InputField from "../../../../components/src/InputFieldPhaseTwo.web";


const validationfeatureSchema = Yup.object().shape({
  featureName: Yup.string()
    .required("Feature Name is required.")
    .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed for Feature Name."),
  featureCategory: Yup.string().required("Feature Category is required."),
  featureFrequency: Yup.string().required("Feature Frequency is required."),
  featureTag: Yup.string().required("Feature Tag is required."),
  defaultFeatureCost: Yup.string().transform((value) => {
    return value ? value.replace(/,/g, '') : value;
  }).test("is-number", "Price Details must be a number.", (value) => !isNaN(Number(value))).required("Price Details are required.").test(
    "is-positive", "Price cannot be negative.", (value) => Number(value) >= 0),
  priceDetails: Yup.string().required("Select Price Details")
});
export class CategoryFilterDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategories: this.props.dataToPass?.categories || [],
      selectedObjectives: this.props.dataToPass?.objectives || [],
      addFeature: this.props.dataToPass?.addFeature || false,
      initialValues: { featureName: "", featureCategory: "", featureFrequency: "", defaultFeatureCost: null, priceDetails: "", },
      featureCategoryOptions: [],
      featureFrequencyOptions: ["Quarterly", "Monthly", "Yearly"],
      featurepriceDetailsOptions: ["Recurring", "One Time"]
    };
    
    const uniqueFeatureSet = new Set();

    const uniqueFeatures = this.props.dataToPass?.data?.filter((feature) => {
      const featureId = feature.attributes.package_category.category_id;
      if (uniqueFeatureSet.has(featureId)) {
        return false;
      } else {
        uniqueFeatureSet.add(featureId);
        return true;
      }
    });
    uniqueFeatures?.map((feature) => (
      this.state.featureCategoryOptions.push({
        featureId: feature.attributes.package_category.category_id,
        featureName: feature.attributes.package_category.category_name,
      })))
  }

  objectiveData = [
    {
      name: "isOwnsABusiness",
      displayName: "Business",
      value: "Business",
    },
    {
      name: "isOwnsABusiness",
      displayName: "Individual",
      value: "Individual",
    },
    {
      name: "isWithAccounting",
      displayName: "Accounting",
      value: "Accounting",
    },
    {
      name: "isWithPayroll",
      displayName: "Payroll",
      value: "Payroll",
    },
    {
      name: "isForNewBusiness",
      displayName: "New Business",
      value: "New Business",
    },
    {
      name: "isForIRSIssues",
      displayName: "IRS Issues",
      value: "IRS Issues",
    },
    {
      name: "isForStrategicAdvisory",
      displayName: "Strategic Help",
      value: "Strategic Help",
    },
  ];
  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };
  handleCategoryChange = (event) => {
    if (event.target.checked) {
      this.setState({
        selectedCategories: [
          ...this.state.selectedCategories,
          event.target.value,
        ],
      });
    } else {
      this.setState({
        selectedCategories: this.state.selectedCategories?.filter((cat) => {
          return cat != event.target.value;
        }),
      });
    }
  };
  handleObjectivesChange = (event) => {
    const value = this.objectiveData.find((ob) => {
      return ob.value == event.target.value;
    });
    if (event.target.checked) {
      if (value?.name == "isOwnsABusiness") {
        const nonValue = value?.value == 'Individual' ? 'Business' : 'Individual'
        this.setState({
          selectedObjectives: this.state.selectedObjectives?.filter((cat) => {
            return cat?.value != nonValue;
          })
        }, () => {
          this.setState({
            selectedObjectives: [...this.state.selectedObjectives, value],
          });
        });
      } else {
        this.setState({
          selectedObjectives: [...this.state.selectedObjectives, value],
        });
      }
    } else {
      this.setState({
        selectedObjectives: this.state.selectedObjectives?.filter((cat) => {
          return cat?.value != event.target.value;
        }),
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { addFeature, initialValues, featureCategoryOptions, featureFrequencyOptions, featurepriceDetailsOptions } = this.state;
    return (addFeature ? (
      <Formik
        initialValues={initialValues}
        validationSchema={validationfeatureSchema}
        onSubmit={(values, { resetForm }) => {
          resetForm();
          this.props.onSubmit(values);
          this.setState({
            defaultFeatureCost: null,
          })
        }}
      >
        {({ isValid, dirty }) => {
          return (
            <Form>
              <Grid container spacing={2} style={{ width: "30vw", padding: "1rem 1rem 2rem 1rem" }}>
                <Grid item xs={12}>
                  <Typography style={{
                    margin: "0"
                  }} variant="h3" align="center" className={classes.dialogHeading}>
                    Add Feature
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Field
                    id="featureName"
                    component={InputField}
                    name="featureName"
                    label="Feature Name"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    id="selectedCat"
                    component={InputField}
                    name="featureCategory"
                    label="Feature Category"
                    select
                    fullWidth
                  >
                    {featureCategoryOptions.map((category, index) => (
                      <MenuItem key={index} value={category.featureId} >
                        {category.featureName}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    id="selectedfeq"
                    component={InputField}
                    name="featureFrequency"
                    label="Feature Frequency"
                    select
                    fullWidth
                  >
                    {featureFrequencyOptions.map((option, index) => (
                      <MenuItem key={index} value={option} id="selectedfeq">
                        {option}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    id="selectedtag"
                    component={InputField}
                    name="featureTag"
                    label="Feature Tag"
                    select
                    fullWidth
                  >
                    {this.objectiveData.map((option, index) => (
                      <MenuItem key={index} value={option.value} id="selectedtag">
                        {option.displayName}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    id="selectedpriced"
                    component={InputField}
                    name="priceDetails"
                    label="Price Details"
                    select
                    fullWidth
                  >
                    {featurepriceDetailsOptions.map((option, index) => (
                      <MenuItem key={index} value={option} id="selectedpriced">
                        {option}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field name="defaultFeatureCost" id="selectedcost">
                    {({ field, form }) => (
                      <NumericFormat
                        {...field}

                        customInput={TextField}
                        label="Default Feature Cost($)"
                        fullWidth
                        thousandSeparator
                        allowNegative={false}
                        onValueChange={(values) => {
                          form.setFieldValue("defaultFeatureCost", values.floatValue || "");
                        }}
                        value={form.values.defaultFeatureCost}
                        InputProps={{
                          style: {
                            padding: "8px",
                            marginTop: "10px"
                          },
                        }}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} >
                  <Box display="flex" justifyContent="center">
                    <Button
                      id="submitform"
                      variant="contained"
                      fullWidth
                      type="submit"
                      color="primary"
                      style={{
                        marginTop: "5px",
                        width: "50%"
                      }}
                      disabled={!(isValid && dirty)}
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>)
        }}
      </Formik>
    ) : (
      <Grid container style={{ width: "30vw" }} justifyContent="center">
        <Grid item xs={12}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Select Category</FormLabel>
            <FormGroup row>
              {[
                "Expert Historic Work",
                "Setup Help",
                "Done-For-You Management",
                "Tax Reduction Planning",
                "Strategic Planning",
              ].map((name) => (
                <FormControlLabel
                  key={name}
                  control={
                    <Checkbox
                      color="primary"
                      id="selectedCategories"
                      checked={this.state.selectedCategories.indexOf(name) > -1}
                      onChange={this.handleCategoryChange.bind(this)}
                      name={"categories"}
                      value={name}
                    />
                  }
                  label={name}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Select Objectives</FormLabel>
            <FormGroup row>
              {this.objectiveData.map((tag) => (
                <FormControlLabel
                  key={tag?.displayName}
                  control={
                    <Checkbox
                      color="primary"
                      id="selectedObjectives"
                      checked={
                        this.state.selectedObjectives
                          .map((ob) => {
                            return ob?.value;
                          })
                          .indexOf(tag?.value) > -1
                      }
                      onChange={this.handleObjectivesChange.bind(this)}
                      name={"objectives"}
                      value={tag?.value}
                    />
                  }
                  label={tag?.displayName}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            id="closeDialog"
            onClick={this.closeDialog.bind(this, {
              categories: this.state.selectedCategories,
              objectives: this.state.selectedObjectives
            })}
            className={classes.uploadNowButton}
          >
            Apply Filter
          </Button>
        </Grid>
      </Grid>
    ));
  }
}

const CategoryFilterDialogWithRouter = withRouter(CategoryFilterDialog);
const CategoryFilterDialogWithToast = withToast(CategoryFilterDialogWithRouter);
const CategoryFilterDialogWithLoader = withLoader(
  CategoryFilterDialogWithToast
);
const CategoryFilterDialogWithAlertBox = withAlertBox(
  CategoryFilterDialogWithLoader
);
const CategoryFilterDialogWithDialogBox = withDialogBox(
  CategoryFilterDialogWithAlertBox
);

export default withStyles((theme) =>
  createStyles({
    fileInput: {
      display: "none",
    },
    crossOverImage: {
      right: "-5px",
      top: "-5px",
      position: "absolute",
      backgroundColor: "red",
      color: "white",
      padding: "3px",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      cursor: "pointer",
    },
    dialogHeading: {
      fontSize: "2rem",
      fontWeight: "bold",
      margin: "1.5rem 0",
      textAlign: "center",
      color: theme.palette.primary.main,
    },

    dialogsubHeading: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      margin: "1.5rem 0",
      textAlign: "center",
    },
    uploadNowButton: {
      margin: "1.5rem 0px",
      width: "40%",
    },
    formControl: {
      margin: theme.spacing(1),
    },
    amoutInputWidth: {
      width: "60%",
      border: "1px solid",
      borderRadius: "0",
      marginBottom: "4px",
    },
    InputPadding: {
      padding: "5px",
    },
    adornedEnd: {
      paddingRight: "6px",
    },
  })
)(CategoryFilterDialogWithDialogBox);
