//@ts-nocheck

import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
import { DialogProps } from "../../../../components/src/DialogContext";
export const configJSON = require("../config");

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  token: string;
  allUsersList: any;
  singleUser: object;
  isPasswordVisible: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ManageUsersController extends BlockComponent<Props, S, SS> {
  getAllUsersApiCallId: string = "";
  getSingleUserApiCallId: string = "";
  createUserApiCallId: string = "";
  updateUserApiCallId: string = "";
  deleteUserApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token: "",
      allUsersList: [],
      isPasswordVisible: false,
      singleUser: {
        type: "firm_employee",
        attributes: {
          first_name: "",
          last_name: "",
          primary_email: "",
          phone_number: "",
          password: "",
          permissions: [
            {
              name: "Account Creation",
              permissions: [
                {
                  id: 17,
                  category: "Account Creation",
                  permission: "Create another's account",
                  is_permitted: false,
                },
                {
                  id: 18,
                  category: "Account Creation",
                  permission: "Modify my account",
                  is_permitted: false,
                },
                {
                  id: 19,
                  category: "Account Creation",
                  permission: "Modify another's account",
                  is_permitted: false,
                },
                {
                  id: 20,
                  category: "Account Creation",
                  permission: "Delete sub account",
                  is_permitted: false,
                },
              ],
            },
            {
              name: "Client Conversion Survey",
              permissions: [
                {
                  id: 21,
                  category: "Client Conversion Survey",
                  permission: "Send Survey",
                  is_permitted: false,
                },
                {
                  id: 22,
                  category: "Client Conversion Survey",
                  permission: "Access Survey Responses",
                  is_permitted: false,
                },
                {
                  id: 23,
                  category: "Client Conversion Survey",
                  permission: "Manage Survey Settings",
                  is_permitted: false,
                },
              ],
            },
            {
              name: "Client Onboarding Survey",
              permissions: [
                {
                  id: 24,
                  category: "Client Onboarding Survey",
                  permission: "Send Survey",
                  is_permitted: false,
                },
                {
                  id: 25,
                  category: "Client Onboarding Survey",
                  permission: "Access Survey Responses",
                  is_permitted: false,
                }
              ],
            },
            {
              name: "CRM",
              permissions: [
                {
                  id: 27,
                  category: "CRM",
                  permission: "Add custom fields",
                  is_permitted: false,
                },
              ],
            },
            {
              name: "Delivery Checklists",
              permissions: [
                {
                  id: 28,
                  category: "Delivery Checklists",
                  permission: "Create a Checklist",
                  is_permitted: false,
                },
                {
                  id: 29,
                  category: "Delivery Checklists",
                  permission: "Manage Checklist",
                  is_permitted: false,
                },
                {
                  id: 30,
                  category: "Delivery Checklists",
                  permission: "Assign Checklist (to packages)",
                  is_permitted: false,
                },
              ],
            },
            {
              name: "eSignatures",
              permissions: [
                {
                  id: 32,
                  category: "eSignatures",
                  permission: "View Documents (pre signature)",
                  is_permitted: false,
                },
                {
                  id: 33,
                  category: "eSignatures",
                  permission: "View Documents (post signature)",
                  is_permitted: false,
                },
              ],
            },
            {
              name: "Firm Financials",
              permissions: [
                {
                  id: 34,
                  category: "Firm Financials",
                  permission: "View/Edit Firm Financials",
                  is_permitted: false,
                },
              ],
            },
            {
              name: "Payments",
              permissions: [
                {
                  id: 35,
                  category: "Payments",
                  permission: "Make payment (CPA Charge)",
                  is_permitted: false,
                },
              ],
            },
            {
              name: "Subscriptions",
              permissions: [
                {
                  id: 37,
                  category: "Subscriptions",
                  permission: "Subscribe",
                  is_permitted: false,
                },
                {
                  id: 38,
                  category: "Subscriptions",
                  permission: "Manage Subscription",
                  is_permitted: false,
                },
                {
                  id: 39,
                  category: "Subscriptions",
                  permission: "Cancel Subscription",
                  is_permitted: false,
                },
              ],
            },
          ],
        },
      },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleGetAllUsersResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson && responseJson?.data) {
      this.setState({
        allUsersList: responseJson?.data,
      });
    } else {
      this.props.showToast({ type: "error", message: responseJson?.message });
      if (typeof responseJson?.errors === "string") {
        this.props.showToast({
          type: "error",
          message: responseJson.errors,
        });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  };

  handleGetSingleUserResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson && responseJson?.data && responseJson?.data?.attributes) {
      this.setState({
        singleUser: responseJson?.data,
      });
    } else {
      this.props.showToast({ type: "error", message: responseJson?.message });
      if (typeof responseJson?.errors === "string") {
        this.props.showToast({
          type: "error",
          message: responseJson.errors,
        });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  };

  handleCreateUserResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson && responseJson?.data && responseJson?.data?.attributes) {
      this.setState({
        singleUser: responseJson.data,
      });
      this.props.showToast({
        type: "success",
        message: "User Created Successfully.",
      });
      this.props.history.push("/home/settings/manageUsers");
    } else if (responseJson?.message) {
      this.props.showToast({ type: "error", message: responseJson?.message });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  handleUpdateUserResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson && responseJson?.data && responseJson?.data?.attributes) {
      this.setState({
        singleUser: responseJson.data,
      });
      this.props.showToast({
        type: "success",
        message: "User Updated Successfully.",
      });
      this.props.history.push("/home/settings/manageUsers");
    } else {
      this.props.showToast({ type: "error", message: responseJson?.message });
      if (typeof responseJson?.errors === "string") {
        this.props.showToast({
          type: "error",
          message: responseJson.errors,
        });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  };

  handleDeleteUserResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson && responseJson?.message === "Successfully Deleted.") {
      this.props.showToast({
        type: "success",
        message: "User Deleted Successfully.",
      });
      this.props.history.push("/home/settings/manageUsers");
    } else {
      this.props.showToast({ type: "error", message: responseJson?.message });
      if (typeof responseJson?.errors === "string") {
        this.props.showToast({
          type: "error",
          message: responseJson.errors,
        });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      switch (apiRequestCallId) {
        case this.getAllUsersApiCallId:
          this.handleGetAllUsersResponse(responseJson);
          break;
        case this.getSingleUserApiCallId:
          this.handleGetSingleUserResponse(responseJson);
          break;
        case this.createUserApiCallId:
          this.handleCreateUserResponse(responseJson);
          break;
        case this.updateUserApiCallId:
          this.handleUpdateUserResponse(responseJson);
          break;
        case this.deleteUserApiCallId:
          this.handleDeleteUserResponse(responseJson);
          break;
        default:
          break;
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(getName(MessageEnum.AlertBodyMessage));
      const msg = await this.props.showAlert({ title: "Error", message: AlertBodyMessage });
      if (msg === 'Session TimeOut,Please Login Again.' || msg === 'User Account Not Found') {
        await StorageProvider.remove("authToken");
        await StorageProvider.remove("accountId");
        this.props.history.push("/");
      }
    }
  }

  getAllUsers = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getAllUsersApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_admin/employee_index`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSingleUser = (id) => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getSingleUserApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_admin/show_employee?id=${id}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createUser = (values: any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.createUserApiCallId = requestMessage.messageId;

    let employeePermissionsAttributes = [];

    values.permissions.forEach((category) => {
      category.permissions.forEach((permission) => {
        let temp = {
          category: permission.category,
          is_permitted: permission.is_permitted,
          permission: permission.permission,
        };
        employeePermissionsAttributes.push(temp);
      });
    });

    const httpBody = {
      employee: {
        primary_email: values.primary_email,
        password: values.password,
        first_name: values.first_name,
        last_name: values.last_name,
        phone_number: values.phone_number,
        employee_permissions_attributes: employeePermissionsAttributes,
      },
    };

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_admin/create_employee");

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateUser = (values: any, id: any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.updateUserApiCallId = requestMessage.messageId;

    let employeePermissionsAttributes = [];

    values.permissions.forEach((category) => {
      category.permissions.forEach((permission) => {
        let temp = {
          category: permission.category,
          id: permission.id,
          is_permitted: permission.is_permitted,
          permission: permission.permission,
        };
        employeePermissionsAttributes.push(temp);
      });
    });

    const httpBody = {
      employee: {
        primary_email: values.primary_email,
        password: values.password,
        first_name: values.first_name,
        last_name: values.last_name,
        phone_number: values.phone_number,
        employee_permissions_attributes: employeePermissionsAttributes,
      },
    };

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_admin/update_employee?id=${id}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteUser = (id: any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deleteUserApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_admin/delete_employee?id=${id}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

}