//@ts-nocheck
import React from "react";
import { withStyles, Grid, Typography, createStyles, Button, Box } from "@material-ui/core";

import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import GetAppIcon from "@material-ui/icons/GetApp";
import html2pdf from "html2pdf.js";

import { googleDrive } from "../assets";

export class RoadmapExportDialog extends React.Component {
  constructor(props: any) {
    super(props);
  }
  opt = {
    margin: 0,
    filename: this.props.dataToPass.values.fileName,
    image: { type: "jpeg", quality: 1 },
    html2canvas: {
      scale: 2,
      useCORS: true,    
      letterRendering: true, 
      removeContainer: true 
    },
    jsPDF: { unit: "mm", format: [889, 304] },
  };
  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };
  responseGoogleSuccess = async () => {
    const content = this.props.dataToPass.values.element.current;
    const contentHeight = content.offsetHeight;
    const contentWidth = content.offsetWidth;
    this.opt.jsPDF.format = [
      (contentWidth * 25.4) / 96, 
      (contentHeight * 25.4) / 96,
    ];
    html2pdf()
      .set(this.opt)
      .from(this.props?.dataToPass.values.element.current)
      .outputPdf("datauristring", {
        filename: this.props?.dataToPass.values.fileName,
      })
      .then(this.processpdf);
  };
  processpdf = async (ababa) => {
    const data = this.props.dataToPass.googleObj;
    const profileData = data?.profileObj;

    if (profileData && profileData.email && profileData.googleId) {
      const currentTime = Math.floor(Date.now() / 1000);
      const expirationTime = data.tokenObj.expires_at / 1000;

      if (expirationTime > currentTime) {
        const boundary = "foo_bar_baz";
        const delimiter = "\r\n--" + boundary + "\r\n";
        const close_delim = "\r\n--" + boundary + "--";
        const fileName = `roadmap_${new Date().toDateString()}_new.pdf`;
        const fileData = ababa.split(',')[1];

        const contentType = "application/pdf";
        const metadata = {
          name: fileName,
          mimeType: contentType,
        };

        const metadataPart =
          delimiter +
          "Content-Type: application/json; charset=UTF-8\r\n" +
          'Content-Disposition: form-data; name="metadata"\r\n\r\n' +
          JSON.stringify(metadata);

        const filePart =
          delimiter +
          "Content-Type: " +
          contentType +
          "\r\n" +
          "Content-Transfer-Encoding: base64\r\n" +
          'Content-Disposition: form-data; name="file"; filename="' +
          fileName +
          '"\r\n\r\n' +
          fileData;

        const multipartRequestBody = metadataPart + filePart + close_delim;

        this.props.showLoader();

        await fetch("https://www.googleapis.com/upload/drive/v3/files", {
          method: "POST",
          params: { uploadType: "multipart" },
          headers: {
            "Content-Type": "multipart/related; boundary=" + boundary,
            Authorization: "Bearer " + data.tokenObj.access_token,
          },
          body: multipartRequestBody,
        }).then((response) => {
          if (response.status === 200 && response.ok) {
            this.props.hideLoader();
            this.closeDialog({ msg: "File Uploaded Sucsessfully" });
          }
        });
      } else {
        this.closeDialog({ msg: "Google token expired, please connect again." });
      }
    } else {
      this.closeDialog({ msg: "google not connected" });
    }
  };
  downloadPDF = async () => {
    const content = this.props.dataToPass.values.element.current;
    const contentHeight = content.offsetHeight;
    const contentWidth = content.offsetWidth;
    this.opt.jsPDF.format = [
      (contentWidth * 25.4) / 96, 
      (contentHeight * 25.4) / 96,
    ];
    html2pdf()
      .set(this.opt)
      .from(this.props?.dataToPass.values.element.current)
      .save();
    this.closeDialog({ msg: "PDF Download started." });
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container justifyContent="space-between" spacing={4} className={classes.rootContainer}>
        <Grid item container justifyContent="center" xs={12}>
          <Grid item xs={5} container justifyContent="center" alignItems="center">
            <Typography align="center" className={classes.driveTitles}>
              Google Drive
            </Typography>
          </Grid>

          <Grid item xs={5} container justifyContent="center" alignItems="center">
            <Typography align="center" className={classes.driveTitles}>
              Local Drive
            </Typography>
          </Grid>

          <Grid item xs={5} container justifyContent="center" alignItems="center">
            <Box className={classes.iconStyles} id="responseGoogleSuccess" onClick={this.responseGoogleSuccess.bind(this)}>
              <img src={googleDrive} alt="Google Drive" height="100%" />
            </Box>
          </Grid>
          <Grid item xs={5} container justifyContent="center">
            <Box className={classes.iconStyles} id="downloadPDF" onClick={this.downloadPDF}>
              <GetAppIcon className={classes.localDownloadIcon} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" color="textPrimary">
              This will download your Roadmap in PDF in your Google Drive or Local Drive. <br /> A Copy will automatically be saved to your SmartPath Account.
            </Typography>
          </Grid>
        </Grid>

        <Grid item container justifyContent="flex-end" xs={12}>
          <Grid item xs={3}>
            <Button variant="contained" color="primary" fullWidth onClick={this.closeDialog}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const RoadmapExportDialogWithRouter = withRouter(RoadmapExportDialog);
const RoadmapExportDialogWithToast = withToast(RoadmapExportDialogWithRouter);
const RoadmapExportDialogWithLoader = withLoader(RoadmapExportDialogWithToast);
const RoadmapExportDialogWithAlertBox = withAlertBox(RoadmapExportDialogWithLoader);

export default withStyles((theme) =>
  createStyles({
    rootContainer: {
      padding: theme.spacing(4),
      color: "black",
    },
    iconStyles: {
      height: theme.spacing(10),
      width: theme.spacing(10),
      padding: theme.spacing(2),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "1px solid rgba(0,0,0,0.2)",
      borderRadius: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(5),
      cursor: "pointer",
    },
    localDownloadIcon: {
      height: "1.5em",
      width: "1.5em",
      color: theme.palette.primary.main,
    },
    driveTitles: {
      color: theme.palette.primary.main,
      fontFamily: "Lato",
      fontSize: "1.4rem",
      fontWeight: "bold",
    },
  })
)(RoadmapExportDialogWithAlertBox);
