import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouteComponentProps } from "react-router";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
import CustomPackagInRoadMap from "../CustomPackagInRoadMap.web";
import { intitalQuestion } from "../../../../components/src/PackageList.web";
export const configJSON = require("../config");
export interface CategoryRoot {
  data: Category[];
}

export interface Category {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  id: number;
  category_name: string;
  features: Features;
}

export interface Features {
  data: Daum2[];
}

export interface Daum2 {
  id: string;
  type: string;
  attributes: Attributes2;
}

export interface Attributes2 {
  id: number;
  feature_id: string;
  tag: string;
  feature_name: string;
  payment_period: string;
  default_cost?: number;
  payment_frequency?: string;
  bump_language?: string;
  package_category: PackageCategory;
}

export interface PackageCategory {
  category_id: number;
  category_name: string;
}

export type Props = RouteComponentProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;

    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  accountId: string | null;
  chipData: {
    key: string;
    label: string;
    isFromQuestionFilter?: boolean;
    type?: string;
  }[];
  allFeatureList: CategoryRoot | any[];
  selectedFeatureList: any;
  token: string | null;
  currentStep: number;
  filterQuestionList: any[];
  currentQuestionIndex: number;
  selectedPackageDetail: null | unknown;
  packageList: any[];
  isCreateFromDefaultTemplate: boolean;
  defaultPackageTemplate: any;
  name: string;
  description: string;
  package_detail_link: string;
  stratagic_rate: number | "";
  value_margin: number | "";
  contract_period: number | "";
  totalOneTimeFess: number;
  totalRecurringFess: number;
  bumpList: any[];
  is_unfront_payment: boolean;
  unfront_payment_type: "percentage" | "lumpsump";
  unfront_percentage: number;
  unfront_lump_suump_amount: number;
  totalContractValue: number;
  totalContractValueAfterRemovingUnfrontAmount: number;
  custom_package_features: any[];
  totalAvailalbeFeatures: number;
  selectedCategory: any;
  selectedSearchField: any;
  mode: "create" | "edit";
  packageType: string;
  edit_package_id: any;
  customPackageCreatedForDefaultPackage: any;
  createCustomPackageId: any;
  allFeatureListForPricingScreen: any[];
  isFromRoadMap: boolean;
  roadMap: any;
  default_firm_finance_strategic_price: any;
  default_strategic_price: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomPackageCreationContainerController extends CustomPackagInRoadMap<
  Props,
  S,
  SS
> {
  updateSurveyLogCallId: string = "";
  getAllFeaturesCallId: string = "";
  getAllFeaturesofSelectedPackageCallId: string = "";
  getPackagesApiCallId: string = "";
  getBumpsApiCallId: string = "";
  createCustomPackageCallId: string = "";
  getAllFeaturesForPriceingScreenCallId: string = "";
  addCustomFeatureInDbCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      accountId: null,
      token: null,
      selectedCategory: [],
      chipData: [],
      packageList: [],
      selectedSearchField: "",
      selectedFeatureList: [],
      allFeatureList: [],
      currentStep: 0,
      isCreateFromDefaultTemplate: false,
      defaultPackageTemplate: "select",
      allFeatureListForPricingScreen: [],
      isFromRoadMap: false,
      roadMap: null,
      filterQuestionList: intitalQuestion,
      currentQuestionIndex: 0,
      selectedPackageDetail: {},
      name: "Custom Package",
      description: "",
      package_detail_link: "",
      stratagic_rate: "",
      value_margin: "",
      contract_period: "",
      totalOneTimeFess: 0,
      totalRecurringFess: 0,
      is_unfront_payment: false,
      unfront_payment_type: "percentage",
      unfront_percentage: 0,
      unfront_lump_suump_amount: 0,
      bumpList: [],
      totalContractValue: 0,
      totalContractValueAfterRemovingUnfrontAmount: 0,
      custom_package_features: [],
      totalAvailalbeFeatures: 0,
      mode: "create",
      packageType: "",
      edit_package_id: null,
      customPackageCreatedForDefaultPackage: null,
      createCustomPackageId: null,
      default_firm_finance_strategic_price: null,
      default_strategic_price: null,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  setFirmFinanceIdealPriceState = (responseJson: any) => {
    if (responseJson?.data?.id) {
      this.setState({
        default_firm_finance_strategic_price:
          responseJson?.data?.attributes?.default_firm_finance_strategic_price,
        default_strategic_price:
          responseJson?.data?.attributes?.default_strategic_price,
      });
    }
  };

  handleSelectedPackageFeatureList = (responseJson: any) => {
    this.props.hideLoader();
    this.setFirmFinanceIdealPriceState(responseJson);
    if (responseJson.data?.attributes?.features?.data?.length) {
      const packageData =
        responseJson.data?.attributes?.custom_package_managements?.data
          ?.attributes || responseJson?.data?.attributes;
      let selectedData = packageData?.features?.data || [];
      selectedData = selectedData.map((item: any, index: any) => {
        return {
          ...item,
          attributes: {
            ...item.attributes,
            unique_feature_id:
              item.attributes.feature_id +
              "_" +
              item.attributes.id +
              "_" +
              index,
          },
        };
      });
      this.setState(
        {
          customPackageCreatedForDefaultPackage:
            responseJson.data?.attributes?.custom_package_managements?.data,
          selectedFeatureList: selectedData,
          name: packageData?.name || "",
          description: packageData?.description || "",
          package_detail_link: packageData?.package_detail_link || "",
          stratagic_rate: packageData?.stratagic_rate || "",
          value_margin: packageData?.value_margin || "",
          contract_period: packageData?.contract_period || "",
          is_unfront_payment: packageData?.payment_term?.is_upfront || false,
          unfront_payment_type:
            packageData?.payment_term?.upfront_percentage > 0
              ? "percentage"
              : "lumpsump",
          unfront_percentage:
            packageData?.payment_term?.upfront_percentage || 0,
          unfront_lump_suump_amount:
            packageData?.payment_term?.upfront_amount || 0,
        },
        () => {
          this.getAllFeatures();
        }
      );
    } else {
      this.getAllFeatures();
      this.handleApiError(responseJson);
    }
  };
  handleGetPackagesResponseInCustomPackage = (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson?.data) {
      this.setState({
        packageList: responseJson?.data,
      });
    } else {
      this.handleApiError(responseJson);
    }
  };

  handleCreatePackageResponse = (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson?.data) {
      this.setState(
        {
          createCustomPackageId: responseJson?.data?.id,
        },
        () => {
          this.props.showToast({
            type: "success",
            message: "Custom Package created successfully",
          });
          this.props.history.push("/home/settings/pricing/firmPricing");
        }
      );
    } else {
      this.handleApiError(responseJson);
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.getAllFeaturesCallId == apiRequestCallId) {
        this.handleAllFeatureList(responseJson);
      } else if (
        this.getAllFeaturesofSelectedPackageCallId === apiRequestCallId
      ) {
        this.handleSelectedPackageFeatureList(responseJson);
      } else if (apiRequestCallId === this.getPackagesApiCallId) {
        this.handleGetPackagesResponseInCustomPackage(responseJson);
      } else if (apiRequestCallId === this.getBumpsApiCallId) {
        this.handleGetBumpResponse(responseJson);
      } else if (apiRequestCallId === this.createCustomPackageCallId) {
        this.handleCreatePackageResponse(responseJson);
      } else if (apiRequestCallId === this.addCustomFeatureInDbCallId) {
        this.props.showToast({
          type: "success",
          message: "Feature added Succesfully",
        });
      } else if (
        apiRequestCallId === this.getAllFeaturesForPriceingScreenCallId
      ) {
        this.handleAllFeatureListForPricing(responseJson);
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showToast({
        type: "error",
        message: AlertBodyMessage,
      });
    }
  }

  getCustomPackageData = (isCreate: boolean) => {
    const data = {
      custom_package: {
        name: this.state.name,
        description: this.state.description,
        package_detail_link: this.state.package_detail_link,
        stratagic_rate: this.state.stratagic_rate,
        value_margin: this.state.value_margin,
        contract_period: this.state.contract_period,
        package_management_id:
          this.state.packageType != "custom_package_management"
            ? this.state.edit_package_id
            : undefined,
        custom_package_features_attributes: this.state.custom_package_features.map(
          (feature, index) => {
            return {
              feature_id: feature?.feature_id,
              tag: feature?.tag,
              feature_name: feature?.feature_name_new || feature?.feature_name,
              payment_period: feature?.payment_period,
              default_cost: feature?.default_cost,
              package_category_id: feature?.package_category_id,
              package_feature_id: feature?.package_feature_id,
              hour: feature?.hour,
              id: !isCreate ? feature?.new_id : undefined,
              strategic_rate: feature?.stratagic_rate,
              payment_frequency: feature?.payment_frequency || undefined,
              _destroy: feature?._destroy || undefined,
              order_number: feature?.order_number || index,
              custom_package_bumps_attributes: feature.custom_package_bumps_attributes.map(
                (bump: any) => {
                  const bumpNew = this.state.bumpList.find((ss) => {
                    return ss.id == bump?.bump_name;
                  });
                  return {
                    bump_type: bumpNew?.attributes?.bump_type,
                    bump_name: bumpNew?.attributes?.bump_name,
                    bump_multiplier: bump.bump_multiplier,
                    bump_amount: bump.bump_amount,
                    total_bump_cost: bump.bump_multiplier * bump.bump_amount,
                    pricing_bump_id: bumpNew?.attributes?.id,
                    feature_name: feature?.feature_name,
                    id: !isCreate ? bump?.id : undefined,
                  };
                }
              ),
            };
          }
        ),
        custom_payment_term_attributes: {
          is_upfront: this.state.is_unfront_payment,
          upfront_percentage:
            this.state.unfront_payment_type == "percentage"
              ? this.state.unfront_percentage
              : undefined,
          upfront_amount:
            this.state.unfront_payment_type == "lumpsump"
              ? this.state.unfront_lump_suump_amount
              : undefined,
          month: Math.round(
            this.state.totalContractValueAfterRemovingUnfrontAmount / 12
          ),
          quarter: Math.round(
            this.state.totalContractValueAfterRemovingUnfrontAmount / 4
          ),
          year: Math.round(
            this.state.totalContractValueAfterRemovingUnfrontAmount / 1
          ),
          total_amount: Math.round(this.state.totalContractValue),
        },
      },
    };
    return data;
  };
  createCustomPackageInDb = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };
    let method = "POST";
    let url = "bx_block_custom_packages/custom_package_creation";
    let isCreate = true;
    if (this.state.packageType == "package_management") {
      if (this.state.customPackageCreatedForDefaultPackage) {
        method = "PUT";
        url += "/" + this.state.customPackageCreatedForDefaultPackage?.id;
        isCreate = false;
      }
    } else if (this.state.packageType == "custom_package_management") {
      method = "PUT";
      url = `bx_block_custom_packages/custom_package_creation/${this.state.edit_package_id}`;
      isCreate = false;
    }
    const data = this.getCustomPackageData(isCreate);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createCustomPackageCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addCustomFeatureInDb = (data: any) => {
    if (data) {
      const headers = {
        "content-type": "application/json",
        token: this.state.token,
      };

      const dataset = {
        "package_feature": {
          "tag": data.featureTag,
          "feature_name": data.featureName,
          "payment_period": data.priceDetails,
          "default_cost": data.defaultFeatureCost,
          "payment_frequency": data.featureFrequency,
          "bump_language": "language",
          "package_category_id": data.featureCategory
        }
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addCustomFeatureInDbCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_custom_packages/create_package_feature`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(dataset)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
  }
}
